import React from 'react';
import {
  Container,
  Stack,
} from '@mui/material';

import { colors } from 'theme';
import Footer from '../footer/footer';
import Header from './header';
import { topNavigationItems } from './navigation';

interface HeaderLayoutProps {
  children: React.ReactNode;
}

function DetailsLayout({ children }: HeaderLayoutProps) {
  return (
    <div style={{ backgroundColor: colors.grayscale.b2, minHeight: '100vh' }}>
      <Header navigationItems={topNavigationItems} />
      <Container maxWidth="xl">
        <Stack pb="88px" pt="22px">
          {children}
        </Stack>
      </Container>
      <Footer bgColor={colors.grayscale.b2} />
    </div>
  );
}

export default DetailsLayout;
