export const CONTROL_POINT = '48.9756748, 38.1390575';

export const TARGET_STATUS = {
  UNPLANNED: 'unplanned',
  PLANNED: 'planned',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

export const TARGET_STATUSES = [
  TARGET_STATUS.UNPLANNED,
  TARGET_STATUS.PLANNED,
  TARGET_STATUS.COMPLETED,
  TARGET_STATUS.ARCHIVED,
];

export const DELTA_LAYER_ID = 'b66b3757-fe5d-4149-a1b0-305c8437a7bc';
