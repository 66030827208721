import React from 'react';

import { IMission } from 'types/mission';

import MissionGroup from './missionGroup';

interface IProps {
    missions: IMission[]
    selectedMission?: IMission | null
    selectMissionAndScrollTop: (mission: IMission) => void
    isFetching: boolean
}

export default function MissionByFinished(
  {
    missions,
    selectedMission,
    selectMissionAndScrollTop,
    isFetching,
  }: IProps,
) {
  const finishedMissions = missions.filter((m) => m.finished);

  return (
    <MissionGroup
      key="finished"
      name="finished"
      missions={finishedMissions}
      selectMission={selectMissionAndScrollTop}
      selectedMissionId={selectedMission?.id}
      isFetching={isFetching}
      expanded={!!finishedMissions?.length}
    />
  );
}

MissionByFinished.defaultProps = {
  selectedMission: null,
};
