import React from 'react';
import BMP from './targetIcons/BMP';
import BTR from './targetIcons/BTR';
import Vehicle from './targetIcons/Viachle';
import EW from './targetIcons/EW';
import UndergroundCover from './targetIcons/UndergroundCover';
import Howitzer from './targetIcons/Howitzer';
import Antenna from './targetIcons/Antenna';
import ZRK from './targetIcons/ZRK';
import RSZV from './targetIcons/RSZV';
import NAI from './targetIcons/NAI';
import Camp from './targetIcons/Camp';

interface IProps {
  sidc: string
  width?: number
  height?: number
}

function TargetSign({ sidc, width, height }: IProps) {
  switch (sidc) {
    // Бойова броньована машина (ББМ, БМП)
    case '10061500001201010000':
    case '10061530001201010000':
      return <BMP width={width} height={height} />;
    // Вантажівка (транспортний засіб підвищеної прохідності)
    case '10061500001408000000':
    case '10061510001408000000':
    case '10061530001408000000':
      return <Vehicle width={width} height={height} />;
    // Створення перешкод
    case '10061000001505040000':
    case '10061010001505040000':
    case '10011000001505040000':
    case '10011000001508000000':
      return <EW width={width} height={height} />;
    // Бронетранспортер (БТР)
    case '10061500001201030000':
    case '10061530001201030000':
    case '10061540001201030000':
      return <BTR width={width} height={height} />;
    // Підземне укриття
    case '10062500002811000000':
    case '10062510002811000000':
      return <UndergroundCover width={width} height={height} />;
    // Гаубиця
    case '10061500001109000000':
      return <Howitzer width={width} height={height} />;
    // Антена
    case '10061500002001000000':
      return <Antenna width={width} height={height} />;
    // Важка пускова установка ЗРК
    case '10061500331111070000':
      return <ZRK width={width} height={height} />;
    // Реактивна система залпового вогню (РСЗВ)
    case '10061500321116000000':
      return <RSZV width={width} height={height} />;
    // Орієнтир (точка інтересу)
    case '10012500001313000000':
      return <NAI width={width} height={height} />;
    // Орієнтир (точка інтересу)
    case '10062000001119000000':
      return <Camp width={width} height={height} />;
    default:
      return <> </>;
  }
}

TargetSign.defaultProps = {
  width: 23,
  height: 23,
};

export default TargetSign;
