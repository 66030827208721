import { ValidationError } from 'yup';

export interface IError {
    [key: string]: string;
}

export const filterAndSimplifyObject = (obj: any) => Object.fromEntries(
  Object.entries(obj)
    .filter(([_, value]) => value !== undefined
        && value !== null
        && !(Array.isArray(value) && value.length === 0)
        && value !== '')
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        value = value.map((item) => item.id);
      }
      return [key, value];
    }),
);

export const getValidationErrors = (err: ValidationError): IError => (err.inner || [])
  .reduce((acc: Record<string, string>, curr) => {
    acc[curr.path!] = curr.message;
    return acc;
  }, {});

export const getTextFieldErrorProps = (
  errors: IError,
  field: string,
  translate: (key: string) => string,
) => ({
  error: !!errors[field],
  helperText: translate(errors[field]),
});

export const toQueryString = (filters: Record<string, any> | null): string => {
  if (!filters) {
    return '';
  }
  const parts: string[] = [];

  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((val) => parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`));
      } else {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  });

  return `?${parts.join('&')}`;
};
