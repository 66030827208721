import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, styled, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { colors } from 'theme';

interface IProps {
  path: string
  label: string
}

const KHBackArrowButton = styled(IconButton)`
  padding-left: 0;
`;

function BackArrowButton({ path, label }: IProps) {
  const navigate = useNavigate();

  return (
    <KHBackArrowButton onClick={() => navigate(path)} disableRipple>
      <ArrowBackIcon fontSize="small" style={{ color: colors.grayscale.g2 }} />
      <Typography variant="body1" pl={3} color={colors.grayscale.g2}>{label}</Typography>
    </KHBackArrowButton>
  );
}

export default BackArrowButton;
