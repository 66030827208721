import React, { createContext, useContext, useState } from 'react';
import api, { apiURLs } from 'services/api';
import { Credentials, User } from './model';

interface IAuthContext {
    user: User | null;
    login: (credentials: Credentials) => Promise<User>;
    switchRole: (role: string) => void;
    logout: () => void;
}

const AuthContext = createContext<IAuthContext>({
  login: () => Promise.resolve({} as User),
  logout: () => null,
  user: null,
  switchRole: (role: string) => null,
});

export const useAuth = () => useContext(AuthContext);

interface Props {
    children: any;
}

export function AuthProvider({ children }: Props) {
  const userString = localStorage.getItem('user');
  const [user, setUser] = useState<User | null>(userString?.length ? JSON.parse(userString) : null);

  const success = (u: User) => {
    const updatedUser = { ...u, currentRole: u.role[0] };
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  const login = (credentials: Credentials): Promise<User> => new Promise((resolve, reject) => {
    api.post(apiURLs.auth.login, credentials)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('authToken', res.data.access_token);
        }
        api.get(apiURLs.auth.profile)
          .then((r) => {
            success(r.data);
            resolve(r.data);
          })
          .catch((err) => {
            const errorMessage = err.response.data?.message;
            reject(errorMessage);
          });
      })
      .catch((err) => {
        const errorMessage = err.response.data?.message;
        reject(errorMessage);
      });
  });

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    setUser(null);
  };

  const switchRole = (role: string) => {
    if (!user) return;
    const updatedUser: User = { ...user, currentRole: role };
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  const contextValue = React.useMemo(() => ({
    user, login, logout, switchRole,
  }), [user]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}
