import { ICrew } from 'types/crew';
import defaultLocation from './location';

const defaultCrew: ICrew = {
  id: '',
  name: '',
  status: 'ready',
  uav: [],
  members: [],
  location: defaultLocation,
  ammo: [],
};

export default defaultCrew;
