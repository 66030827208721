import React from 'react';
import {
  Button, FormControlLabel, Grid, Stack, Switch, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTextFieldErrorProps, IError } from 'services/helpers';
import { roles, status } from '../services/constants';
import User from '../services/model';

interface Props {
  user: User | null;
  setUser: (props: any) => void;
  errors: IError;
}

export default function UserFields({ user, setUser, errors }: Props) {
  const { t } = useTranslation();

  const toggleRole = (role: string) => {
    if (!user) return;
    if (user?.role.includes(role)) {
      setUser({ ...user, role: user.role.filter((r) => r !== role) });
    } else {
      setUser({ ...user, role: [...user.role, role].filter(Boolean) });
    }
  };

  const updateUser = (field: string, value: string) => {
    setUser({ ...user, [field]: value });
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={3}>
        <Typography variant="h3">{t('callSign')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField
          fullWidth
          value={user?.username}
          onChange={(e) => setUser({ ...user, username: e.target.value })}
          size="small"
          {...getTextFieldErrorProps(errors, 'username', t)}
        />
      </Grid>
      <Grid item xs={12} md={2} />
      <Grid item xs={12} md={3} alignItems="center">
        <Typography variant="h3">{t('role')}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Stack direction="row" gap={1} sx={{ overflowX: 'auto' }}>
          {roles.map((role) => (
            <Button
              key={role}
              variant={user?.role.includes(role) ? 'contained' : 'outlined'}
              color={user?.role.includes(role) ? 'primary' : 'secondary'}
              sx={{ minWidth: 'auto', padding: '10px 20px' }}
              onClick={() => toggleRole(role)}
            >
              {t(role)}
            </Button>
          ))}
        </Stack>
        {!!errors.role && <Typography color="error" pl={2} variant="caption">{t(errors.role)}</Typography>}
      </Grid>
      <Grid item xs={12} md={3} alignContent="center">
        <Typography variant="h3">{t('status')}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <FormControlLabel
          control={(
            <Switch
              checked={user?.status === status.ACTIVE}
              onChange={(e) => updateUser('status', e.target.checked ? status.ACTIVE : status.INACTIVE)}
            />
          )}
          label={t(user?.status || status.INACTIVE)}
        />
      </Grid>
    </Grid>
  );
}
