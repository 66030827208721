import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { urls } from 'router';
import { colors } from 'theme';
import EditButton from 'components/buttons/editButton';

import { defaultFilters } from 'types/mission';
import { IGetZone } from 'types/zone';
import NAIList from './naiList';

interface IProps {
  selectedZone: IGetZone
  selectZoneAndScrollTop: (zone: IGetZone | null) => void
}

const DetailsCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px',
  border: 'none',
  backgroundColor: colors.grayscale.b2,
}));

const HistoryCard = styled(Card)(() => ({
  marginBottom: '8px',
  padding: '10px 16px',
  border: `1px solid ${colors.grayscale.b5}`,
  backgroundColor: colors.grayscale.b3,
}));

function ZoneDetailsSidebar({ selectedZone, selectZoneAndScrollTop }: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const gotoFilteredMissions = () => {
    const missionFilters = {
      ...defaultFilters,
      zones: [selectedZone],
    };
    localStorage.setItem('filters', JSON.stringify(missionFilters));
    navigate(urls.missions.landing);
  };

  return (
    <DetailsCard>
      <Stack flexDirection="row" justifyItems="center" justifyContent="space-between" mb={2}>
        <IconButton aria-label="close" onClick={() => selectZoneAndScrollTop(null)} style={{ padding: '0', color: colors.grayscale.g2 }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack flexDirection="column">
        <Typography variant="body2" mb={1}>{t('zone_details_header')}</Typography>
        <Typography variant="h1" mb={1}>{selectedZone.name}</Typography>
        <Typography variant="body1" mb={1}>{selectedZone.description}</Typography>
        <Stack flexDirection="row" mb={2}>
          <EditButton
            onClick={() => navigate(urls.zones.edit(selectedZone?.id))}
          />
        </Stack>
        <HistoryCard>
          <Typography variant="body1" color={colors.grayscale.g1} mb="22px">NAI {selectedZone.nais?.length}</Typography>
          <NAIList zone={selectedZone} />
        </HistoryCard>
      </Stack>
      {(selectedZone.missions?.length && selectedZone.missions?.length > 0) && (
        <Button style={{ margin: 'auto' }} onClick={gotoFilteredMissions} variant="outlined" color="primary">{t('all_zone_missions')}</Button>
      )}
    </DetailsCard>
  );
}

export default ZoneDetailsSidebar;
