import React from 'react';

import { IMission } from 'types/mission';

import missionStatuses from '../services/constants';
import MissionGroup from './missionGroup';

interface IProps {
  missions: IMission[]
  selectedMission?: IMission | null
  selectMissionAndScrollTop: (mission: IMission) => void
  isFetching: boolean
}

function MissionByStatus(
  {
    missions,
    selectedMission,
    selectMissionAndScrollTop,
    isFetching,
  }: IProps,
) {
  const filterMissionsType = (type: string, missionsList: IMission[] | undefined) =>
    /* eslint-disable implicit-arrow-linebreak */
    missionsList?.filter((mission) => mission.status === type);

  return (
    <>
      {
        missionStatuses.map((missionType) => {
          const filteredMissions = filterMissionsType(missionType, missions);
          return (
            <MissionGroup
              key={missionType}
              name={missionType}
              missions={filteredMissions}
              selectMission={selectMissionAndScrollTop}
              selectedMissionId={selectedMission?.id}
              isFetching={isFetching}
              expanded={!!filteredMissions?.length}
            />
          );
        })
      }
    </>
  );
}

MissionByStatus.defaultProps = {
  selectedMission: null,
};

export default MissionByStatus;
