import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Paper, Popover, Stack, styled, Typography,
} from '@mui/material';

import { colors } from 'theme';
import { TMissionStatus } from 'types/mission';
import { TCrewStatus } from 'types/crew';
import { TTargetStatus } from 'types/target';
import { StatusLabel } from './statusLabel';

interface IProps {
  id: string
  type: 'mission' | 'crew' | 'target'
  currentStatus: TMissionStatus | TCrewStatus | TTargetStatus
  selectable?: boolean
  options?: TCrewStatus[] | TMissionStatus[]
  onClick?: (status: any) => void
  size?: 'sm' | 'md'
}

const StyledPaper = styled(Paper)`
  padding: 12px 48px 2px 12px;
  background-color: ${colors.grayscale.b1};
  border: 1px solid ${colors.grayscale.b3};
`;

function StatusLabelDropdown(
  {
    type,
    id,
    currentStatus,
    selectable,
    options,
    onClick,
    size,
  }: IProps,
) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (option: TCrewStatus | TMissionStatus) => {
    if (onClick) onClick(option);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return selectable ? (
    <div>
      <StatusLabel aria-describedby={id} className={`${currentStatus}`} onClick={handleClick}>
        {t(`status_${type}_${currentStatus}`)}
        <ArrowDropDownIcon fontSize="small" />
      </StatusLabel>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: -4,
          horizontal: 0,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledPaper>
          {options?.map((option) => (
            <Stack key={option} alignItems="flex-start" mb="10px">
              <StatusLabel onClick={() => handleOnClick(option)} className={`${option}`}>{t(`status_${type}_${option}`)}</StatusLabel>
            </Stack>
          ))}
        </StyledPaper>
      </Popover>
    </div>
  ) : (
    <StatusLabel className={`${currentStatus}`} disabled>
      <Typography variant="body1" style={{ color: 'inherit', fontSize: size === 'sm' ? '12px' : '14px' }}>
        {t(`status_${type}_${currentStatus}`)}
      </Typography>
    </StatusLabel>
  );
}

StatusLabelDropdown.defaultProps = {
  selectable: false,
  options: '',
  onClick: () => {},
  size: 'md',
};

export default StatusLabelDropdown;
