import React from 'react';

import { colors } from 'theme';

interface IProps {
  fill?: string
  width?: number
  height?: number
}

function StarIcon({ width, height, fill }: IProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00031 12.8936L4.30031 15.7186C4.11697 15.8686 3.91697 15.9393 3.70031 15.9306C3.48364 15.9226 3.29197 15.8603 3.12531 15.7436C2.95864 15.6269 2.82964 15.4686 2.73831 15.2686C2.64631 15.0686 2.64197 14.8519 2.72531 14.6186L4.15031 9.9936L0.525307 7.4186C0.325307 7.28527 0.200307 7.11027 0.150307 6.8936C0.100307 6.67694 0.10864 6.47694 0.175307 6.2936C0.241974 6.11027 0.35864 5.9476 0.525307 5.8056C0.691974 5.66427 0.891973 5.5936 1.12531 5.5936H5.60031L7.05031 0.793603C7.13364 0.56027 7.26297 0.380937 7.43831 0.255604C7.61297 0.130937 7.80031 0.0686035 8.00031 0.0686035C8.20031 0.0686035 8.38764 0.130937 8.56231 0.255604C8.73764 0.380937 8.86697 0.56027 8.95031 0.793603L10.4003 5.5936H14.8753C15.1086 5.5936 15.3086 5.66427 15.4753 5.8056C15.642 5.9476 15.7586 6.11027 15.8253 6.2936C15.892 6.47694 15.9003 6.67694 15.8503 6.8936C15.8003 7.11027 15.6753 7.28527 15.4753 7.4186L11.8503 9.9936L13.2753 14.6186C13.3586 14.8519 13.3546 15.0686 13.2633 15.2686C13.1713 15.4686 13.042 15.6269 12.8753 15.7436C12.7086 15.8603 12.517 15.9226 12.3003 15.9306C12.0836 15.9393 11.8836 15.8686 11.7003 15.7186L8.00031 12.8936Z" fill={fill} />
    </svg>
  );
}

StarIcon.defaultProps = {
  fill: colors.system.yellow1,
  width: 16,
  height: 16,
};

export default StarIcon;
