import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import FakeModal from 'components/fakeModal';
import { urls } from 'router';
import { ICreateZoneDto, IZone, ZoneFormModes } from 'types/zone';
import defaultZone from 'defaults/zone';
import { getValidationErrors } from 'services/helpers';
import api, { apiURLs } from 'services/api';
import ZoneForm from '../components/zoneForm';

interface IProps {
  currentZone?: IZone | ICreateZoneDto
  zoneId?: string
}

const schema = yup.object({
  name: yup.string().required('name_required').min(2, 'name_too_short'),
}).required();

function CreateZone({ zoneId, currentZone }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [zone, setZone] = React.useState<ICreateZoneDto | IZone | undefined>(defaultZone);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => setZone(currentZone), [currentZone]);

  const mutationCb = (res: any, reset: any) => {
    reset();
    navigate(urls.zones.landing);
    toast.success(t('zone_created'));
    return res.data;
  };

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['submit-zone'],
    mutationFn: (c: ICreateZoneDto | IZone) => (zoneId
      ? api.patch(apiURLs.zones.updateZone(zoneId), c)
        .then((res) => {
          mutationCb(res, reset);
        })
        .catch((err) => toast.error(err.response.data?.error))
      : api.post(apiURLs.zones.create, c)
        .then((res) => {
          mutationCb(res, reset);
        })
        .catch((err) => toast.error(err.response.data?.error))
    ),
  });

  function validateAndSubmit() {
    schema.validate(zone, { abortEarly: false }).then(() => {
      setErrors({});
      if (zone) {
        submit(zone);
      }
    }).catch((err: ValidationError) => {
      setErrors(getValidationErrors(err));
    });
  }

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t('create_zone')}
        cancel={() => navigate(urls.zones.landing)}
        onSubmit={() => validateAndSubmit()}
      >
        {/* @ts-ignore */}
        <ZoneForm zone={zone} setZone={setZone} errors={errors} mode={ZoneFormModes.CREATE} />
      </FakeModal>
    </Container>
  );
}

CreateZone.defaultProps = {
  currentZone: defaultZone,
  zoneId: '',
};

export default CreateZone;
