import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';

import { uavTypes } from 'constants/mission';
import MissionTypeIcon from '../missionTypeIcon';

interface IProps {
  selectedType: string | undefined
  setSelectedType: (type: string | undefined) => void
  error: string | null
}

function MissionTypeSelector({ selectedType, setSelectedType, error }: IProps) {
  const { t } = useTranslation();

  return (
    <>
      <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
        {uavTypes.map((type) => (
          <Button
            key={type}
            variant={selectedType === type ? 'contained' : 'outlined'}
            color={selectedType === type ? 'primary' : 'secondary'}
            sx={{ minWidth: 'auto', padding: '10px 20px' }}
            onClick={() => {
              if (selectedType === type) {
                setSelectedType(undefined);
                return;
              }
              setSelectedType(type);
            }}
          >
            <Stack flexDirection="row" alignItems="center" gap="6px">
              {t(type)}
              <MissionTypeIcon type={`${type}${selectedType === type ? 'Active' : ''}`} />
            </Stack>
          </Button>
        ))}
      </Stack>
      {error && <Typography variant="body2" color="error" ml={2}>{error}</Typography>}
    </>
  );
}

export default MissionTypeSelector;
