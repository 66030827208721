import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import { ITarget } from 'types/target';
import { getDefaultDate } from 'services/dayjs';

interface IProps {
  target: ITarget
  short?: boolean
  reconTarget?: boolean
}

function TargetDetailsInfo({ target, short, reconTarget }: IProps) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} mb={2}>
      {!short ? (
        <>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>{t('name_in_delta')}</Typography>
            <Typography variant="body1">{target.name || '-'}</Typography>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>{t('quantity')}</Typography>
            <Typography variant="body1">{target.quantity || 1}</Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="body2" mb={1}>{t('type')}</Typography>
            <Typography variant="body1">{target.app6d_type}</Typography>
          </Grid>
        </>
      ) : null}
      {!reconTarget ? (
        <>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>{t('source')}</Typography>
            <Typography variant="body1">{target.platform_type || '-'}</Typography>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>{t('reliability_source')}</Typography>
            <Typography variant="body1">{target.reliability_credibility || '-'}</Typography>
          </Grid>
        </>
      ) : null}
      <Grid item xs={6} mb={2}>
        <Typography variant="body2" mb={1}>{t('coordinates')}</Typography>
        <Typography variant="body1">{target.coordinates || '-'}</Typography>
      </Grid>
      <Grid item xs={6} mb={2}>
        <Typography variant="body2" mb={1}>{t('observation_time')}</Typography>
        <Typography variant="body1">{getDefaultDate(target.observation_datetime) || '-'}</Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="body2" mb={1}>{t('delta_comment')}</Typography>
        <Typography variant="body1">{target.staff_comment || '-'}</Typography>
        {target.delta_comments?.map((comment) => (
          <Typography variant="body1">{comment}</Typography>
        ))}
      </Grid>
      {!short ? (
        <Grid item xs={12} mb={2}>
          <Typography variant="body2" mb={1}>{t('records')}</Typography>
          <Typography variant="body1">{target.comments || '-'}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

TargetDetailsInfo.defaultProps = {
  short: false,
  reconTarget: false,
};

export default TargetDetailsInfo;
