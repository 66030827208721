import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function BMP({ width, height }: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" width={width} height={height} viewBox="26 26 148 148">
      <text x="192" y="70" textAnchor="start" fontSize="60" fontFamily="Arial" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" stroke="#ffffff" fill="none" />
      <path d="M100,28 L172,100 100,172 28,100 100,28 Z Z" strokeWidth="44" strokeLinecap="round" strokeLinejoin="round" stroke="none" fill="none" />
      <path d="M100,28 L172,100 100,172 28,100 100,28 Z" strokeWidth="4" stroke="black" fill="rgb(255,128,128)" fillOpacity="1" />
      <path d="m 70,80 60,0 m -60,40 60,0 m -60,-50 0,60 0,0 m 60,-60 0,60" strokeWidth="4" stroke="black" fill="none" />
      <text x="192" y="70" textAnchor="start" fontSize="60" fontFamily="Arial" strokeWidth="4" stroke="none" fill="#000000" />
    </svg>
  );
}

BMP.defaultProps = {
  width: 23,
  height: 23,
};

export default BMP;
