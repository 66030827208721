import React from 'react';
import {
  Stack,
  styled,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BombIcon from 'assets/icons/bomb';

import { colors } from 'theme';

interface IProps {
  type: string
}

const KHUav = styled(Stack)`
  min-height: 20px;
  padding: 1px 6px;
  border-radius: 14px;
  background-color: ${colors.grayscale.b5};
`;

function UavTypeIcon({ type }: IProps) {
  return (
    <KHUav flexDirection="row" alignItems="center" gap={1}>
      { type === 'recon' ? <VisibilityIcon fontSize="small" style={{ color: colors.grayscale.g2, height: '16px' }} /> : null }
      { type === 'strike' ? <RocketLaunchIcon fontSize="small" style={{ color: colors.grayscale.g2, width: '17px' }} /> : null }
      { type === 'ammunition' ? <BombIcon color={colors.grayscale.g2} /> : null }
    </KHUav>
  );
}

export default UavTypeIcon;
