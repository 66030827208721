import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { IReport } from '../../types/mission';
import { colors } from '../../theme';
import { dayjs } from '../../services/dayjs';

interface IProps {
  report: IReport | undefined;
}

const ReportBox = styled(Box)`
  background-color: ${colors.brand.b1};
  border: 1px solid ${colors.brand.b2};
  border-radius: 10px;
  padding: 18px;
`;

export default function MissionReport({ report }: IProps) {
  if (!report) {
    return null;
  }
  return (
    <ReportBox>
      <Typography variant="body1">{report?.text}</Typography>
      {report?.created_at && (
      <Typography sx={{ pt: 1 }} color={colors.grayscale.g1} variant="body2">
        {dayjs(report?.created_at).fromNow()}
      </Typography>
      )}
    </ReportBox>
  );
}
