import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowUp } from '@mui/icons-material';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Stack, styled, Typography,
} from '@mui/material';

import { ICrew } from 'types/crew';
import { colors } from 'theme';
import MissionTypeIcon from 'components/missionTypeIcon';
import CrewCard from './crewCard';

interface Props {
  name: string
  crews: ICrew[]
  expanded: boolean
  selectCrew: (id: string) => void
  selectedCrewId?: string
  isFetching?: boolean
}

const KHAccordion = styled(Accordion)`
  .MuiAccordionSummary-content {
    margin: 20px 0;
  }
  .MuiAccordionDetails-root {
    padding: 0 18px 16px;
  }
  .MuiCardContent-root {
    padding: 8px 15px;
    &:last-child {
      padding: 8px 15px;
    }
  }
`;

function CrewGroup(
  {
    crews,
    name,
    expanded,
    selectCrew,
    selectedCrewId,
    isFetching,
  }: Props,
) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(expanded);

  if (isFetching) return <Skeleton height={50} />;

  return (
    <KHAccordion expanded={isExpanded} onChange={(e, exp) => setIsExpanded(exp)}>
      <AccordionSummary>
        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          <KeyboardArrowUp
            style={{
              color: colors.grayscale.g1,
              transform: !isExpanded ? 'rotate(180deg)' : '',
              transition: '300ms ease-out',
            }}
          />
          <MissionTypeIcon type={name} />
          <Typography variant="h3">{t(name)}</Typography>
          <Typography variant="h3" color={colors.grayscale.g2}>{crews?.length || '0'}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1}>
          {crews?.map((crew) => (
            <CrewCard
              crew={crew}
              key={crew.id}
              selectCrew={selectCrew}
              selectedCrewId={selectedCrewId}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </KHAccordion>
  );
}

CrewGroup.defaultProps = {
  selectedCrewId: '',
  isFetching: false,
};

export default CrewGroup;
