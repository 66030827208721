import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './authProvider';
import { role } from '../admin/users/services/constants';

interface IProps {
    allowRoles: string[];
}

function PrivateRoute({ allowRoles }: IProps) {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!allowRoles.includes(user.currentRole)) {
    if (user.currentRole === role.ADMIN) {
      return <Navigate to="/admin" />;
    }
    if (user.currentRole === role.CREW_MEMBER) {
      return <Navigate to="/pilot/missions" />;
    }
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default PrivateRoute;
