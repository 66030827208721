import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Button, styled, Typography } from '@mui/material';

interface IProps {
  onClick?: () => void
  disabled?: boolean
  hideLabel?: boolean
  label?: string
}

const StylesButton = styled(Button)`
  padding: 4px 12px;
`;

function AddButton(
  {
    onClick,
    disabled,
    hideLabel,
    label,
  }: IProps,
) {
  const { t } = useTranslation();

  return (
    <StylesButton variant="outlined" color="secondary" onClick={onClick} disabled={disabled}>
      <AddIcon fontSize="small" style={{ paddingRight: '6px' }} />
      <Typography variant="body1">{!hideLabel && (label || t('add'))}</Typography>
    </StylesButton>
  );
}

AddButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  hideLabel: false,
  label: '',
};

export default AddButton;
