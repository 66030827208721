import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowUp } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box, Button, Divider, Menu, MenuItem, Stack, styled, Typography,
} from '@mui/material';

import { useAuth } from 'features/login/authProvider';
import { colors } from 'theme';
import RoleSwitcher from './roleSwitcher';

const KHMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${colors.grayscale.b1};
    background-image: none;
    box-shadow: 0 10px 20px RGBA(0, 0, 0, .25);
    border-radius: 16px;
    margin-top: 9px;
    min-width: 220px;
    padding: 5px;
  }

  .MuiMenuItem-root:hover {
    background-color: transparent;
  }
`;

function User() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, logout } = useAuth();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Button variant="contained" onClick={handleClick} color="primary">
          <Stack flexDirection="row" justifyContent="center" alignItems="center">
            {user?.username.replace(/^\w/, (c) => c.toUpperCase())}, {t(user?.currentRole || '')}
            <Stack ml={1}>
              { open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp /> }
            </Stack>
          </Stack>
        </Button>
      </Box>
      <KHMenu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
      >
        <RoleSwitcher />
        <Divider />
        <MenuItem onClick={handleLogout} disableRipple>
          <Typography variant="body1" color="error">Вийти</Typography>
        </MenuItem>
      </KHMenu>
    </>
  );
}

export default User;
