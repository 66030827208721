// eslint-disable-next-line import/prefer-default-export
export const crewTypes = {
  RECON: 'recon',
  STRIKE: 'strike',
};

export const crewStatus: any = {
  READY: 'ready',
  NOT_READY: 'not_ready',
  OPERATING: 'operating',
};

export const crewStatuses = [
  crewStatus.READY,
  crewStatus.OPERATING,
  crewStatus.NOT_READY,
];
