import React from 'react';
import {
  Box, Button, Checkbox, FormControlLabel, styled, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { colors } from 'theme';
import api, { apiURLs } from 'services/api';
import { IGetMissionListResponse, IMission } from 'types/mission';
import { missionStatus } from '../../../constants/mission';

interface FinishMissionButtonProps {
    mission: IMission;
    onClose: Function;
}

const GreenContainer = styled(Box)`
    border: 2px solid ${colors.brand.b3_1};
    border-radius: 10px;
    padding: 10px;
    background-color: ${colors.brand.b1};
`;

const checks = [
  { label: 'files_uploaded', field: 'filesUploaded' },
  { label: 'ortophotoplan', field: 'ortophotoplan' },
  { label: '3d_model', field: 'model' },
  { label: 'decrypted_put_in_delta', field: 'decryptedPutInDelta' },
];

export default function FinishMissionButton({ mission, onClose }: FinishMissionButtonProps) {
  const { t } = useTranslation();
  const [values, setValues] = React.useState(mission.checklist || []);
  const queryClient = useQueryClient();
  const hideChecklist = mission.type !== 'recon' || [missionStatus.SUSPENDED, missionStatus.NOT_COMPLETED].includes(mission.status);

  React.useEffect(() => {
    setValues(mission.checklist || []);
  }, [mission]);

  const {
    mutate: closeMission, reset,
  } = useMutation({
    mutationKey: ['close-mission'],
    mutationFn: (id: string) => (api.patch(apiURLs.missions.updateMission(id), { finished: true })
      .then(({ data: d }) => {
        reset();
        toast.success(t('mission_status_updated'));
        onClose();
        return d;
      })
      .catch((err) => toast.error(err.response.data?.error))
    ),
    onSuccess: (updatedMission: IMission) => queryClient.getQueryCache().findAll({ queryKey: ['mission-list'] }).forEach((x) => {
      queryClient.setQueryData(x.queryKey, (missionsResponse: IGetMissionListResponse) => ({
        ...missionsResponse,
        results: missionsResponse.results.map((m) => {
          if (m.id === updatedMission.id) {
            return updatedMission;
          }
          return m;
        }),
      }));
    }),
  });

  const {
    mutate: updateChecklist, reset: resetUpdate,
  } = useMutation({
    mutationKey: ['update-mission'],
    mutationFn: (checklist: string[]) => (api
      .patch(apiURLs.missions.updateMissionChecklist(mission.id), { checklist })
      .then(({ data: d }) => {
        resetUpdate();
        toast.success(t('mission_result_updated_successfully'));
        return d;
      })
      .catch((err) => toast.error(err.response.data?.error))
    ),
    onSuccess: (updatedMission: IMission) => queryClient.getQueryCache().findAll({ queryKey: ['mission-list'] }).forEach((x) => {
      queryClient.setQueryData(x.queryKey, (missionsResponse: IGetMissionListResponse) => ({
        ...missionsResponse,
        results: missionsResponse.results.map((m) => {
          if (m.id === updatedMission.id) {
            return updatedMission;
          }
          return m;
        }),
      }));
    }),
  });

  if (![
    missionStatus.NOT_COMPLETED,
    missionStatus.COMPLETED,
    missionStatus.SUSPENDED,
  ].includes(mission.status)) {
    return null;
  }

  function button() {
    return (
      <Button
        variant="contained"
        onClick={() => {
          closeMission(mission.id);
        }}
        sx={{
          width: '100%',
          backgroundColor: `${colors.brand.b3}`,
          border: 'none',
        }}
      >
        {t('approve_and_close')}
      </Button>
    );
  }

  if (hideChecklist) {
    if (mission.finished) {
      return null;
    }
    return button();
  }

  return (
    <GreenContainer>
      <Typography variant="body2" color={colors.brand.b4}>{t('approve_to_close_mission')}</Typography>
      {checks.map((check) => (
        <Box key={check.label}>
          <FormControlLabel
            value="top"
            control={(
              <Checkbox
                checked={values?.includes(check.field)}
                disabled={mission.finished}
                onChange={(e) => {
                  let newValues;
                  if (e.target.checked) {
                    newValues = [...values, check.field];
                  } else {
                    newValues = values.filter((v) => v !== check.field);
                  }
                  setValues(newValues);
                  updateChecklist(newValues);
                }}
              />
              )}
            label={t(check.label)}
          />
        </Box>
      ))}
      {!mission.finished && button()}
    </GreenContainer>
  );
}
