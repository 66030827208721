import React from 'react';
import {
  Table,
  styled,
} from '@mui/material';

import { colors } from 'theme';

interface IProps {
  children: React.ReactNode
}

const KHTableStyled = styled(Table)`
  .MuiTableHead-root .MuiTableRow-head {
    border-radius: 10px 0 0 0;
    border: 1px solid ${colors.grayscale.b4};
  }
  .MuiTableHead-root .MuiTableCell-root:first-child {
    padding: 15px 16px;
  }
  .MuiTableRow-root {
    height: 54px;
    border: 1px solid ${colors.grayscale.b4};
    background-color: ${colors.grayscale.b2};
  }
  .MuiTableBody-root .MuiTableRow-root {
    cursor: pointer;
  }
  .MuiTableCell-root {
    border-bottom: 1px solid ${colors.grayscale.b4};
  }
  .MuiTableRow-hover .action-icon {
    visibility: hidden;
  }
  .MuiTableRow-hover:hover .action-icon {
    visibility: visible;
  }
`;

function KHTable({ children }: IProps) {
  return (
    <KHTableStyled size="small">{children}</KHTableStyled>
  );
}

export default KHTable;
