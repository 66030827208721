import React from 'react';
import {
  Box, styled, Tab, Tabs,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationItem } from './navigation';

const NavigationLink = styled(Link)`
  padding-left: 40px;
  text-decoration: none;
`;

interface IProps {
    pathname: string;
    navigationItems: NavigationItem[];
    orientation: 'vertical' | 'horizontal';
}

export default function TopNavigation({ navigationItems, pathname, orientation }: IProps) {
  const { t } = useTranslation();
  const parentPath = `/${pathname.split('/')[1]}`;
  return (
    <Box flexDirection="row" display="flex" alignItems="center">
      <Tabs value={parentPath} className="nav" orientation={orientation}>
        {navigationItems.map((item: NavigationItem) => (
          <Tab
            key={item.name}
            label={t(item.name)}
            value={item.href}
            to={item.href}
            color="primary"
            component={NavigationLink}
            disableRipple
            sx={{ marginTop: 1, marginBottom: 1, alignItems: 'flex-start' }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
