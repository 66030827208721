import React from 'react';

interface IProps {
  fill?: string
  width?: number
  height?: number
}

function ArchiveIcon({ width, height, fill }: IProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 11.3332H17.5V18.0015C17.5 18.4607 17.1292 18.8332 16.6725 18.8332H3.3275C3.21855 18.833 3.1107 18.8113 3.01013 18.7693C2.90956 18.7274 2.81824 18.6661 2.74139 18.5888C2.66454 18.5116 2.60366 18.42 2.56224 18.3192C2.52082 18.2184 2.49968 18.1105 2.5 18.0015V11.3332ZM2.5 2.99817C2.5 2.539 2.87084 2.1665 3.3275 2.1665H16.6725C17.1292 2.1665 17.5 2.53817 17.5 2.99817V9.6665H2.5V2.99817ZM7.5 4.6665V6.33317H12.5V4.6665H7.5ZM7.5 13.8332V15.4998H12.5V13.8332H7.5Z" fill="#AFAFAF" />
    </svg>

  );
}

ArchiveIcon.defaultProps = {
  fill: 'none',
  width: 16,
  height: 16,
};

export default ArchiveIcon;
