import React from 'react';

interface IProps {
  width?: number
  height?: number
  fill?: string
}

function Logo({ width, height, fill }: IProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M23.999 25.8162L12.9069 36.9059L16.001 40L24.0206 31.9779L32.0211 39.9784L35.0912 36.9059L23.999 25.8162Z" fill={fill} />
        <path d="M47.9981 23.999L32.6716 8.67259L26.2074 15.252V7.66443L28.5814 10.1848L31.3778 7.38838L23.999 0L16.6203 7.38838L19.4167 10.1848L21.7907 7.66443V15.252L15.3265 8.67259L0 23.999L3.02688 27.0283L15.2736 14.7816L20.6577 20.168L8.41335 32.4124L11.6059 35.6073L23.999 23.2141L36.3922 35.6073L39.5847 32.4124L27.3404 20.168L32.7244 14.7816L44.9712 27.0283L47.9981 23.999Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_121_2946">
          <rect width="47.9981" height="40" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

Logo.defaultProps = {
  width: 42,
  height: 42,
  fill: '#FFFFFF',
};

export default Logo;
