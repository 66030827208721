import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { toast } from 'react-toastify';

import { urls } from 'router';
import api, { apiURLs } from 'services/api';
import PageHeader from 'components/pageHeader';
import { IGetUAVResponse, IUAV } from 'types/uav';
import UAVTable from '../components/uavTable';
import { BaseFilters } from '../../../../services/model';

function AdminUAVPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState<IUAV[]>([]);

  const search = new URLSearchParams(location.search).get('search');
  const filters = { page: 1, limit: 10, search } as BaseFilters;
  const queryClient = useQueryClient();

  const {
    data, isFetching, isLoading, refetch,
  } = useQuery({
    queryKey: ['uav', filters],
    queryFn: (): Promise<IGetUAVResponse> => api.get(apiURLs.admin.uavs.list(filters))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (data?.results && currentPage === 1) {
      setResults(data.results);
    }
  }, [data]);

  const loadMore = async () => {
    setCurrentPage(currentPage + 1);
    try {
      filters.page = currentPage + 1;
      const previousData = results;
      const newResults = await refetch();
      setResults([...previousData || [], ...newResults.data?.results || []]);
    } catch (err) {
      toast.error((err as Error).message);
    }
  };

  const deleteMutation = useMutation({
    mutationFn: (id: string) => api.delete(apiURLs.admin.uavs.delete(id)),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['uav', filters] }); },
  });

  const deleteItem = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id);
      toast.success(t('uavDeleted'));
    } catch (error: any) {
      toast.error(error.response.data?.error);
    }
  };

  return (
    <>
      <PageHeader createLabel={t('add')} title={t('uav')} onCreate={() => navigate(urls.admin.uav.create)} />
      <Box sx={{ overflowX: 'auto' }}>
        <UAVTable
          uavs={results}
          isLoading={isLoading}
          deleteItem={deleteItem}
        />
      </Box>
      <Stack direction="row" justifyContent="center" className="footer-container">
        {/* @ts-ignore */}
        <Button variant="outlined" color="primary" className="footer-button" onClick={loadMore} disabled={isFetching || currentPage >= (data?.pageCount || 0)}>{t('loadMore')}</Button>
      </Stack>
    </>
  );
}

export default AdminUAVPage;
