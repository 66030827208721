import React from 'react';

interface IProps {
  width?: number
  height?: number
}

function Logo({ width, height }: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="3.08 5.21 99.84 69.57">
      <path d="M53.1404 37.9551L39.0695 52.0259L42.9942 55.9456L53.1653 45.7744L63.3115 55.9156L67.2062 52.0259L53.1404 37.9551Z" fill="white" />
      <path d="M83.5739 35.6532L64.1354 16.2147L55.9365 24.5584V14.9365L58.9524 18.1321L62.4976 14.587L53.1404 5.21472L43.7781 14.587L47.3233 18.1321L50.3392 14.9365V24.5584L42.1403 16.2147L22.7018 35.6532L26.5416 39.493L42.0704 23.9642L48.9011 30.7949L33.3723 46.3237L37.4218 50.3732L53.1404 34.6546L68.854 50.3732L72.9034 46.3237L57.3746 30.7949L64.2053 23.9642L79.7341 39.493L83.5739 35.6532Z" fill="white" />
      <path d="M3.09314 74.7799H6.85801L12.2956 70.8502L17.6883 74.7799H21.6329V72.4031L15.2666 69.6469V69.2224L21.6329 66.4612V64.1194H17.888L12.4304 68.049L7.02278 64.1194H3.07816V66.4612L9.44448 69.2674V69.6918L3.09314 72.4031V74.7799Z" fill="white" />
      <path d="M22.7014 74.78H25.5975L27.0605 72.8276H37.2816L38.7446 74.78H41.8354V72.4631L34.1109 64.1145H30.4409L22.7014 72.4631V74.78ZM32.543 66.5112L35.5739 70.5507H28.7782L31.809 66.5112H32.543Z" fill="white" />
      <path d="M46.7237 69.3422V66.5261H55.7564L56.3656 67.1353V68.7331L55.7564 69.3422H46.7237ZM42.9588 74.7798H46.7237V71.6241H58.1481L60.1754 69.5969V66.1516L58.1332 64.1094H42.9588V74.7798Z" fill="white" />
      <path d="M61.2439 66.6459H67.9448V74.7799H71.7246V66.6459H78.4255V64.1194H61.2439V66.6459Z" fill="white" />
      <path d="M83.5785 64.1194H79.7986V74.7849H83.5785V64.1194Z" fill="white" />
      <path d="M89.5603 68.5334V67.1303L90.1695 66.5211H99.1573V69.3373H92.2716L89.5603 68.5334ZM102.922 64.1194H87.7778L85.7505 66.1466V70.1362L89.7101 70.9601V71.5543L85.7505 72.3632V74.7849H89.5154L95.4723 71.4943H99.1573V74.7849H102.922V64.1194Z" fill="white" />
    </svg>
  );
}

Logo.defaultProps = {
  width: 104,
  height: 80,
};

export default Logo;
