import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Stack,
  Typography,
  Card,
  styled,
  CardContent, TextField,
} from '@mui/material';
import { MuiChipsInput, MuiChipsInputChip } from 'mui-chips-input';

import { ICreateZoneDto, ZoneFormModes } from 'types/zone';

import { colors } from 'theme';
import { getTextFieldErrorProps, IError } from 'services/helpers';

interface IProps {
  setZone: (zone: ICreateZoneDto) => void
  zone: ICreateZoneDto
  errors: IError
  mode: string
}

const Chips = styled(MuiChipsInput)`
  .MuiInputBase-root {
    padding: 5px;
    padding-left: 4px;
  }
  .MuiInputBase-root input {
      padding: 8px 14px;
  }
  .MuiChip-root {
    background-color: ${colors.brand.b3};
  }
`;

const FormCard = styled(CardContent)(() => ({
  backgroundColor: `${colors.grayscale.b2}`,
  border: `1px solid ${colors.grayscale.b4}`,
  padding: '32px 50px',
}));

function ZoneForm({
  setZone, zone, errors, mode = '',
}: IProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: MuiChipsInputChip[]) => {
    if (zone) {
      setZone({ ...zone, nais: newValue });
    }
  };

  return (
    <Stack gap="2px">
      <Card style={{ border: 'none' }}>
        <FormCard>
          <Grid container>
            <Grid item md={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={3} alignContent="center">
                  <Typography variant="h3">{t('zone_name')}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    size="small"
                    placeholder={t('zone_name')}
                    fullWidth
                    onChange={(e) => setZone({ ...zone, name: e.target.value })}
                    value={zone?.name || ''}
                    {...getTextFieldErrorProps(errors, 'name', t)}
                  />
                </Grid>
                <Grid item xs={3} alignContent="center">
                  <Typography variant="h3">{t('description')}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    size="small"
                    placeholder={t('description')}
                    fullWidth
                    onChange={(e) => setZone({ ...zone, description: e.target.value })}
                    value={zone?.description || ''}
                    {...getTextFieldErrorProps(errors, 'description', t)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormCard>
      </Card>
      {
        mode === ZoneFormModes.CREATE
        && (
        <Card style={{ border: 'none' }}>
          <FormCard>
            <Grid container>
              <Grid item md={12} lg={8}>
                <Grid container spacing={2}>
                  <Grid item xs={3} alignContent="center">
                    <Typography variant="h3">{t('create_nai')}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Chips
                      value={zone?.nais}
                      onChange={handleChange}
                      placeholder={t('nai_name_enter')}
                      size="medium"
                      fullWidth
                      hideClearAll
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormCard>
        </Card>
        )
      }
    </Stack>
  );
}

export default ZoneForm;
