import { getDistance } from 'geolib';

import { CONTROL_POINT } from 'constants/target';

function getDistanceToTarget(locationDistance: number, targetLocation: string) {
  const pointA = CONTROL_POINT.split(', ');
  const pointB = targetLocation.split(', ');
  const distance = getDistance(
    { latitude: pointA[0], longitude: pointA[1] },
    { latitude: pointB[0], longitude: pointB[1] },
  );
  return ((distance / 1000) + (locationDistance / 1000)).toFixed(2);
}

export default getDistanceToTarget;
