import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardContent, Grid, Stack, styled, TextField, Typography,
} from '@mui/material';

import { colors } from 'theme';
import { getTextFieldErrorProps, IError } from 'services/helpers';
import { IUAV } from 'types/uav';
import MissionTypeSelector from '../../../../components/missionTypeSelector';

const FormCard = styled(Card)(() => ({
  border: 'none',
}));

const FormCardContent = styled(CardContent)(() => ({
  padding: 0,
  backgroundColor: `${colors.grayscale.b2}`,
  border: 'none',
}));

interface Props {
  uav: IUAV;
  setUAV: (uav: IUAV) => void;
  errors: IError;
}

export default function UAVForm({ uav, setUAV, errors }: Props) {
  const { t } = useTranslation();

  const updateUAV = (field: string, value: string | undefined) => {
    setUAV({ ...uav, [field]: value });
  };

  return (
    <Stack>
      <FormCard>
        <FormCardContent>
          <Grid container p={4}>
            <Grid item xs={12} md={12} lg={8}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3">Назва</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder="Введіть назву"
                    value={uav?.name}
                    onChange={(e) => setUAV({ ...uav, name: e.target.value })}
                    size="small"
                    {...getTextFieldErrorProps(errors, 'name', t)}
                  />
                </Grid>
                <Grid item xs={12} md={4} />
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3">{t('type')}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <MissionTypeSelector
                    selectedType={uav.type}
                    setSelectedType={(type) => updateUAV('type', type)}
                    error={null}
                  />
                </Grid>
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3" mb="6px">Дальність</Typography>
                  <Typography variant="body2" color="#5F5F5F">Опціонально</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    type="number"
                    placeholder="0"
                    value={uav?.range}
                    onChange={(e) => setUAV({ ...uav, range: parseInt(e.target.value, 10) })}
                    InputProps={{
                      endAdornment: <Typography variant="body2">км</Typography>,
                      inputProps: { min: 0 },
                    }}
                    size="small"
                    {...getTextFieldErrorProps(errors, 'range', t)}
                  />
                </Grid>
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3" mb="6px">Деталі</Typography>
                  <Typography variant="body2" color="#5F5F5F">Опціонально</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder="Додайте деталі"
                    value={uav?.description}
                    onChange={(e) => setUAV({ ...uav, description: e.target.value })}
                    size="small"
                    {...getTextFieldErrorProps(errors, 'description', t)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormCardContent>
      </FormCard>
    </Stack>
  );
}
