import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { TTargetStatus } from 'types/target';
import { IMission, TMissionStatus } from 'types/mission';
import { colors } from 'theme';
import StatusLabel from '../../../components/statusLabelDropdown';
import MissionTypeIcon from '../../../components/missionTypeIcon';
import UavTypeIcon from '../../../components/uavTypeIcon';

interface IProps {
  targetId: string
  currentStatus: TTargetStatus | TMissionStatus
  statusType: 'target' | 'mission' | 'crew'
  path: string
  mission: IMission
}

const HistoryCard = styled(Card)(() => ({
  marginBottom: '8px',
  border: `1px solid ${colors.grayscale.b5}`,
  backgroundColor: colors.grayscale.b3,
}));

function TargetStatus(
  {
    targetId,
    currentStatus,
    statusType,
    mission,
    path,
  }: IProps,
) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <HistoryCard style={{ marginBottom: '16px', padding: '12px' }}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb="6px">
        <Stack flexDirection="row" gap="6px">
          <Typography variant="body1">{mission.name}</Typography>
          <MissionTypeIcon type={mission.type} />
        </Stack>
        <StatusLabel type={statusType} currentStatus={currentStatus} id={targetId} />
      </Stack>
      <Stack mb="12px" flexDirection="row" alignItems="center" gap="5px">
        <Typography variant="body2">{mission.crew?.name} · {mission.crew?.uav[0]?.name}</Typography>
        <UavTypeIcon type={mission.crew.uav[0]?.type} />
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Button size="small" variant="outlined" color="primary" onClick={() => navigate(path)} disabled={false}>{t('open_mission')}</Button>
        </Stack>
      </Stack>
    </HistoryCard>
  );
}

export default TargetStatus;
