import React from 'react';
import {
  Button, Card, CardContent, Grid, Stack, styled,
} from '@mui/material';
import { colors } from 'theme';
import { IError } from 'services/helpers';
import { useTranslation } from 'react-i18next';
import User from '../services/model';
import PasswordForm from './passwordForm';
import UserFields from './userFields';

const FormCard = styled(Card)(() => ({
  border: 'none',
}));

const FormCardContent = styled(CardContent)(() => ({
  padding: 0,
  backgroundColor: `${colors.grayscale.b2}`,
  border: 'none',
}));

interface Props {
  user: User | null;
  setUser: (props: any) => void;
  errors: IError;
  onUserSubmit: () => void;
  onPasswordSubmit: () => void;
  onCancel: () => void;
}

export default function EditUserForm({
  user,
  setUser,
  errors,
  onUserSubmit,
  onPasswordSubmit,
  onCancel,
}: Props) {
  const { t } = useTranslation();

  const updateUser = (field: string, value: string) => {
    setUser({ ...user, [field]: value });
  };

  return (
    <Stack gap={2}>
      <FormCard>
        <FormCardContent>
          <Grid container p={4}>
            <Grid item xs={12} md={8}>
              <UserFields user={user} setUser={setUser} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" gap={2} mt={5}>
                <Button variant="contained" color="primary" size="large" onClick={onUserSubmit}>{t('save')}</Button>
                <Button variant="outlined" color="secondary" size="large" onClick={onCancel}>{t('cancel')}</Button>
              </Stack>
            </Grid>
          </Grid>
        </FormCardContent>
      </FormCard>
      <FormCard>
        <FormCardContent>
          <Grid container p={4}>
            <Grid item xs={8}>
              <PasswordForm
                passwords={{ password: user?.password || '', confirm_password: user?.confirm_password || '' }}
                setPasswords={updateUser}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" gap={2} mt={5}>
                <Button variant="contained" color="primary" size="large" onClick={onPasswordSubmit}>{t('save')}</Button>
                <Button variant="outlined" color="secondary" size="large" onClick={onCancel}>{t('cancel')}</Button>
              </Stack>
            </Grid>
          </Grid>
        </FormCardContent>
      </FormCard>
    </Stack>
  );
}
