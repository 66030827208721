import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { urls } from 'router';
import FakeModal from 'components/fakeModal';
import api, { apiURLs } from 'services/api';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { Container } from '@mui/material';
import { getValidationErrors, IError } from 'services/helpers';
import { Ammunition } from '../services/model';
import AmmunitionForm from '../components/ammunitionForm';

const defaultAmmunitionValue: Ammunition = {
  name: '',
  description: '',
  quantity: 0,
};

const schema = yup.object({
  name: yup.string().required('name_required').min(1, 'name_too_short'),
  quantity: yup.number().min(0, 'quantity_too_low'),
  description: yup.string(),
}).required();

function CreateAmmunition() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [ammunition, setAmmunition] = React.useState<Ammunition>(defaultAmmunitionValue);
  const [errors, setErrors] = React.useState<IError>({});

  const {
    isLoading, data: details,
  } = useQuery({
    enabled: !!id,
    queryKey: ['ammunition-details', id],
    queryFn: (): Promise<Ammunition> => api.get(apiURLs.admin.ammunition.details(id as string))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const mutationCb = (reset: () => void) => {
    reset();
    navigate(urls.admin.ammunition.landing);
    toast.success(t(id ? 'ammunitionUpdated' : 'ammunitionCreated'));
  };

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['submit-ammunition'],
    mutationFn: () => (id
      ? api.patch(apiURLs.admin.ammunition.patch(id as string), ammunition)
        .then(() => {
          mutationCb(reset);
        })
      : api.post(apiURLs.admin.ammunition.create, ammunition)
        .then(() => {
          mutationCb(reset);
        })
        .catch((err) => toast.error(t(err.response.data?.errorCode)))
    ),
  });

  React.useEffect(() => {
    if (details) {
      setAmmunition(details);
    }
  }, [details]);

  const validateAndSubmit = (a: Ammunition) => {
    schema.validate(a, { abortEarly: false }).then(() => {
      setErrors({});
      submit();
    }).catch((err: ValidationError) => {
      setErrors(getValidationErrors(err));
    });
  };

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t('create_ammunition')}
        cancel={() => navigate(urls.admin.ammunition.landing)}
        onSubmit={() => validateAndSubmit(ammunition)}
        isLoading={isLoading}
      >
        <AmmunitionForm ammunition={ammunition} setAmmunition={setAmmunition} errors={errors} />
      </FakeModal>
    </Container>
  );
}

export default CreateAmmunition;
