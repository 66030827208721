import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, styled } from '@mui/material';

import StarIcon from 'assets/icons/star';
import { colors } from 'theme';

interface IProps {
  checked: boolean
  setChecked: (checked: boolean) => void
  small?: boolean
}

const StyledButton = styled(Button)`
  color: ${colors.system.yellow1};
  border: 2px solid ${colors.system.yellow2};
  padding: 3px 8px;
  min-width: auto;
  &:hover {
    background-color: transparent;
  };
  &.selected {
    color: ${colors.system.yellow2};
    border: 2px solid ${colors.system.yellow1};
    background-color: ${colors.system.yellow1};
  };
`;

function FavoriteButton({ checked, setChecked, small }: IProps) {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={() => setChecked(!checked)} className={checked ? 'selected' : ''}>
      {small ? (
        <StarIcon fill={checked ? colors.system.yellow2 : colors.system.yellow1} />
      ) : (
        <>
          <StarIcon fill={checked ? colors.system.yellow2 : colors.system.yellow1} />
          <span style={{ paddingLeft: '8px' }}>
            {checked ? t('remove_from_favourites') : t('add_to_favourites')}
          </span>
        </>
      )}
    </StyledButton>

  );
}

FavoriteButton.defaultProps = {
  small: false,
};

export default FavoriteButton;
