import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { urls } from 'router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import api, { apiURLs } from 'services/api';
import FakeModal from 'components/fakeModal';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { getValidationErrors, IError } from 'services/helpers';
import { Container } from '@mui/material';
import CreateUserForm from '../components/createUserForm';
import User from '../services/model';
import { roles, status } from '../services/constants';
import EditUserForm from '../components/editUserForm';

const defaultUserValue: User = {
  username: '',
  role: [roles[0]],
  status: status.ACTIVE,
  password: '',
  confirm_password: '',
};

const usernameShema = yup.string().required('username_required').min(2, 'username_too_short');
const passwordSchema = yup.string().required('password_required').min(8, 'password_must_at_least_eight_characters_long')
  .test('uppercase', 'password_must_contain_one_uppercase_letter', (value) => /[A-Z]/.test(value as string))
  .test('lowercase', 'password_must_contain_one_lowercase_letter', (value) => /[a-z]/.test(value as string));
const confirmPasswordSchema = yup.string().oneOf([yup.ref('password')], 'passwords_do_not_match');

const userSchema = yup.object({
  username: usernameShema,
  password: passwordSchema,
  role: yup.array().min(1, 'role_required'),
  confirm_password: confirmPasswordSchema,
}).required();

const editUserSchema = yup.object({
  username: usernameShema,
  role: yup.array().min(1, 'role_required'),
}).required();

const editPasswordSchema = yup.object({
  password: passwordSchema,
  confirm_password: confirmPasswordSchema,
}).required();

export default function CreateUser() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user, setUser] = React.useState<User>(defaultUserValue);
  const [errors, setErrors] = React.useState<IError>({});

  const {
    isLoading, data: details,
  } = useQuery({
    enabled: !!id,
    queryKey: ['user-details', id],
    queryFn: (): Promise<User> => api.get(apiURLs.admin.users.details(id as string))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const mutationCb = (res: any, reset: any) => {
    reset();
    navigate(urls.admin.landing);
    toast.success(t(id ? 'userUpdated' : 'userCreated'));
    return res.data;
  };

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['submit-user'],
    mutationFn: () => (id
      ? api.patch(apiURLs.admin.users.patch(id as string), user)
        .then((res) => {
          mutationCb(res, reset);
        })
        .catch((err) => toast.error(err.response.data?.error))
      : api.post(apiURLs.admin.users.create, user)
        .then((res) => {
          mutationCb(res, reset);
        })
        .catch((err) => toast.error(t(err.response.data?.errorCode)))
    ),
  });

  const {
    mutate: updatePassword,
  } = useMutation({
    mutationKey: ['submit-user'],
    mutationFn: () => api.post(apiURLs.admin.users.updatePassword(id as string), {
      newPassword: user.password,
    })
      .then((res) => {
        mutationCb(res, reset);
      })
      .catch((err) => toast.error(err.response.data?.error)),
  });

  React.useEffect(() => {
    if (details) {
      setUser(details);
    }
  }, [details]);

  const validateAndSubmit = (u: User, schema: any) => {
    schema.validate(u, { abortEarly: false }).then(() => {
      setErrors({});
      submit();
    }).catch((err: ValidationError) => {
      setErrors(getValidationErrors(err));
    });
  };

  const validateAndSubmitPassword = (u: User, schema: any) => {
    schema.validate(u, { abortEarly: false }).then(() => {
      setErrors({});
      updatePassword();
    }).catch((err: ValidationError) => {
      setErrors(getValidationErrors(err));
    });
  };

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t(id ? 'editUser' : 'createUser')}
        onSubmit={() => validateAndSubmit(user, userSchema)}
        cancel={() => navigate(urls.admin.landing)}
        isLoading={isLoading}
        hideControls={!!id}
      >
        {id ? (
          <EditUserForm
            user={user as User}
            setUser={setUser}
            errors={errors}
            onUserSubmit={() => validateAndSubmit(user, editUserSchema)}
            onPasswordSubmit={() => validateAndSubmitPassword(user, editPasswordSchema)}
            onCancel={() => navigate(urls.admin.landing)}
          />
        ) : (
          <CreateUserForm
            user={user as User}
            setUser={setUser}
            errors={errors}
          />
        )}
      </FakeModal>
    </Container>
  );
}
