import React from 'react';
import * as _ from 'lodash';

import { IMission } from 'types/mission';
import { IUAV } from 'types/uav';

import MissionGroup from './missionGroup';

interface IProps {
  missions: IMission[]
  selectedMission?: IMission | null
  selectMissionAndScrollTop: (mission: IMission) => void
  isFetching: boolean
}

interface IMissionListByCrew {
  crewId: string,
  crewName: string,
  crewUAV: IUAV[],
  missions: IMission[],
}

function MissionByCrew(
  {
    missions,
    selectedMission,
    selectMissionAndScrollTop,
    isFetching,
  }: IProps,
) {
  const filterMissionsByCrew = (missionsList: IMission[] | undefined) => {
    const filteredList: IMissionListByCrew[] = [];
    const activeMissions = missionsList?.filter((m) => !m.finished);
    const listByCrewId = _.groupBy(activeMissions, (mission) => mission.crew?.id);

    if (missionsList) {
      Object.keys(listByCrewId)
        .map((crewId: string) => (
          filteredList?.push({
            missions: listByCrewId[crewId],
            crewName: listByCrewId[crewId][0].crew?.name || '',
            crewUAV: listByCrewId[crewId][0].crew?.uav,
            crewId,
          })
        ));
    }

    return filteredList;
  };

  return (
    <>
      {
        filterMissionsByCrew(missions).map((missionByCrew) => (
          <MissionGroup
            key={missionByCrew.crewId}
            name={missionByCrew.crewName}
            missions={missionByCrew.missions}
            selectMission={selectMissionAndScrollTop}
            selectedMissionId={selectedMission?.id}
            expanded
          />
        ))
      }
    </>
  );
}

MissionByCrew.defaultProps = {
  selectedMission: null,
};

export default MissionByCrew;
