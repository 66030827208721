import React from 'react';
import { Stack, styled, Typography } from '@mui/material';

import { CameraIcon, CommentIcon, TargetIcon } from 'assets/icons';
import { colors } from 'theme';

const CountsContainer = styled(Stack)`
  padding: 2px 6px;
  background-color: ${colors.grayscale.b2};
  border-radius: 10px;
`;

interface IProps {
  photos?: number
  comments?: number
  missions?: number
}

function TargetCounts({ photos, comments, missions }: IProps) {
  if (!photos && !comments && !missions) {
    return null;
  }
  return (
    <CountsContainer flexDirection="row" gap="10px">
      { photos ? (
        <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="4px">
          <CameraIcon />
          <Typography variant="body2">{photos}</Typography>
        </Stack>
      ) : null }
      { missions ? (
        <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="4px">
          <TargetIcon />
          <Typography variant="body2">{missions}</Typography>
        </Stack>
      ) : null }
      { comments ? (
        <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="4px">
          <CommentIcon />
          <Typography variant="body2">{comments}</Typography>
        </Stack>
      ) : null }
    </CountsContainer>
  );
}

TargetCounts.defaultProps = {
  photos: 0,
  comments: 0,
  missions: 0,
};

export default TargetCounts;
