import React from 'react';
import {
  Box, Button, Card, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../theme';

export default function () {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ p: '10%' }}>
      <Card sx={{ background: colors.grayscale.b2 }}>
        <Box alignItems="center" display="flex" justifyContent="center" flexDirection="column" p={3}>
          <Typography variant="h3">{t('join_crew_to_see_missions_and_targets')}</Typography>
          <Typography variant="body1" mt={1.5} mb={3}>{t('contact_istar')}</Typography>
          <Button variant="contained" onClick={() => navigate(0)}>{t('refresh_page')}</Button>
        </Box>
      </Card>
    </Box>
  );
}
