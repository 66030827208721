import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Stack, TextField, Typography,
} from '@mui/material';

import { useAuth } from 'features/login/authProvider';
import PasswordField from 'components/passwordField';
import { colors } from 'theme';
import Logo from './Logo';

import './login.css';

function LoginPage() {
  const { user, login } = useAuth();
  const { t } = useTranslation();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');

  const signIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login({ username, password }).then(() => {
      console.log('success');
    }).catch((err) => {
      setError(err);
    });
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div style={{ backgroundColor: colors.grayscale.b3, minHeight: '100vh' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Stack p={4} alignItems="center">
          <Stack mb="48px"><Logo /></Stack>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            maxWidth="440px"
            className="login-container"
          >
            <Stack m={4}>
              <Typography variant="h1" textAlign="center" mb={2} lineHeight="36px">{t('welcome_to_khartiia')}</Typography>
              <Typography variant="body2" textAlign="center" m={6} mt={0} mb={4}>{t('enter_login_password')}</Typography>
              <form onSubmit={signIn}>
                <Stack mb={3}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    placeholder={t('callSign')}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username || ''}
                    onChange={(e) => setUsername(e.target.value)}
                    size="small"
                    InputProps={{ style: { fontSize: 16 } }}
                  />
                </Stack>
                <PasswordField
                  placeholder={t('password')}
                  value={password || ''}
                  onChange={(pass) => {
                    setError('');
                    setPassword(pass);
                  }}
                  autoComplete="current-password"
                  error={error}
                  helperText={t(error)}
                  InputProps={{ style: { fontSize: 16 } }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t('signIn')}
                </Button>
              </form>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </div>
  );
}

export default LoginPage;
