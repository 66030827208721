import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Dialog, Hidden, Stack } from '@mui/material';

import { ICrew, IGetCrewListResponse } from 'types/crew';
import api, { apiURLs } from 'services/api';
import { urls } from 'router';
import PageHeader from 'components/pageHeader';
import CrewGroup from '../components/crewGroup';
import { crewTypes } from '../services/constants';
import CrewDetailsSidebar from '../components/crewDetailsSidebar';
import { Transition } from '../../../components/header/mobileMenu';

function Crews() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const [selectedCrewId, setSelectedCrewId] = useState<string | null>();
  const [crews, setCrews] = React.useState<ICrew[]>([]);

  const {
    data, isFetching,
  } = useQuery({
    queryKey: ['crew-list', search],
    queryFn: (): Promise<IGetCrewListResponse> => api.get(apiURLs.crews.listExtended(search))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  React.useEffect(() => {
    if (data?.results && !isFetching) {
      setCrews(data.results);
    }
  }, [data, isFetching]);

  const filterCrewsType = (type: string, crewsList: ICrew[] | undefined) => (
    crewsList?.filter((crew) => crew.uav?.length && crew.uav[0].type === type));

  const selectCrewAndScrollTop = (id: string) => {
    if (selectedCrewId === id) {
      setSelectedCrewId(null);
    } else {
      setSelectedCrewId(id);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const crew = crews.find((c: ICrew) => c.id === selectedCrewId);

  return (
    <>
      <PageHeader title={t('crews')} onCreate={() => navigate(urls.crews.create)} createLabel={t('createCrew')} />
      <Stack flexDirection="row" gap={2}>
        <Stack gap={1} flex={1}>
          {Object.keys(crewTypes).map((type) => (
            <CrewGroup
              key={type}
              // @ts-ignore
              name={crewTypes[type] === 'strike' ? 'bomb' : crewTypes[type]}
              // @ts-ignore
              crews={filterCrewsType(crewTypes[type], crews)}
              selectCrew={selectCrewAndScrollTop}
              selectedCrewId={selectedCrewId || undefined}
              isFetching={isFetching}
              expanded
            />
          ))}
        </Stack>
        {crew && (
          <>
            <Hidden mdDown>
              <CrewDetailsSidebar crew={crew} setSelectedCrewId={setSelectedCrewId} />
            </Hidden>
            <Hidden mdUp>
              <Dialog
                open={!!selectedCrewId}
                TransitionComponent={Transition}
                fullScreen
                sx={{
                  marginTop: '30px',
                  '& .MuiDialog-paper': {
                    borderRadius: '20px',
                  },
                }}
              >
                <CrewDetailsSidebar crew={crew} setSelectedCrewId={setSelectedCrewId} />
              </Dialog>
            </Hidden>
          </>
        )}
      </Stack>
    </>
  );
}

export default Crews;
