import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Card, Divider, Grid, IconButton, Link, Stack, styled, Typography,
} from '@mui/material';

import { ICrew, TCrewStatus } from 'types/crew';
import { urls } from 'router';
import { colors } from 'theme';

import { last } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';

import CrewDetailsInfo from './crewDetailsInfo';
import CrewMissions from './crewMissions';

import './crewDetailsSidebar.css';
import { crewStatus } from '../services/constants';
import ReasonAlert from '../../../components/reasonAlert';
import EditButton from '../../../components/buttons/editButton';
import { useUpdateCrewStatus } from '../services/hooks';
import CrewStatusConfirmation from '../../pilot/components/crewStatusConfirmation';

interface IProps {
  crew: ICrew
  setSelectedCrewId: (id: string | null) => void
}

const DetailsCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px',
  border: 'none',
  backgroundColor: colors.grayscale.b2,
}));

function CrewDetailsSidebar({ crew: crewDefault, setSelectedCrewId }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const updateCrewStatus = useUpdateCrewStatus(crewDefault);
  const [statusConfirmation, setStatusConfirmation] = React.useState(false);

  const [crew, setCrew] = React.useState<ICrew>(crewDefault);
  const {
    data,
  } = useQuery({
    queryKey: ['crew-details', crewDefault.id],
    queryFn: (): Promise<ICrew> => api.get(apiURLs.crews.details(crewDefault.id))
      .then((res) => res.data)
      .catch(() => crew),
  });

  React.useEffect(() => {
    if (data) setCrew(data);
  }, [data]);

  const onStatusChange = (status: TCrewStatus) => {
    if (status === crewStatus.NOT_READY) {
      setStatusConfirmation(true);
      return;
    }
    updateCrewStatus.mutate({ id: crew.id, status });
  };

  const activeMissions = crew.mission?.filter((m) => !m.finished) || [];

  return (
    <Stack gap={1} flex={1}>
      <DetailsCard>
        <Stack flexDirection="row" justifyItems="center" justifyContent="space-between" mb={2}>
          <IconButton aria-label="close" onClick={() => setSelectedCrewId(null)} style={{ padding: '0', color: colors.grayscale.g2 }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack flex={1}>
            <Typography variant="body2" mb={1}>Деталі екіпажу</Typography>
            <Stack flexDirection="row" justifyContent="space-between" display="flex" alignItems="center">
              <Link href={urls.crews.edit(crew.id)}>
                <Typography variant="h1" mb={2}>{crew.name}</Typography>
              </Link>
              <EditButton
                onClick={() => navigate(urls.crews.edit(crew.id))}
                hideLabel
              />
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 2, mt: 2 }} />
        <CrewDetailsInfo
          crew={crew}
          onStatusChange={(status: string) => onStatusChange(status as TCrewStatus)}
        />
        {crew.status === crewStatus.NOT_READY && (
          <Grid item xs={12}>
            <ReasonAlert
              reason={last(crew.reports)}
              color="red"
              title={t('reason_crew_not_ready')}
            />
          </Grid>
        )}
        {(activeMissions.length > 0) && (
          <>
            <Divider style={{ marginBottom: '16px' }} />
            <CrewMissions activeMissions={activeMissions} />
          </>
        )}
      </DetailsCard>
      <CrewStatusConfirmation
        open={statusConfirmation}
        onClose={() => setStatusConfirmation(false)}
        onSave={(report: string) => updateCrewStatus.mutate({
          id: crew.id,
          status: crewStatus.NOT_READY,
          report,
        })}
      />
    </Stack>
  );
}

export default CrewDetailsSidebar;
