import React, { useState } from 'react';
import { Button, Stack, styled } from '@mui/material';
import { MuiChipsInput, MuiChipsInputChip } from 'mui-chips-input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import api, { apiURLs } from 'services/api';
import { colors } from 'theme';
import AddButton from 'components/buttons/addButton';
import { IGetZone } from 'types/zone';
import NAIListItem from './naiListItem';

interface IProps {
  zone: IGetZone
}

const Chips = styled(MuiChipsInput)`
  .MuiInputBase-root {
    padding: 5px;
    padding-left: 4px;
  }
  .MuiInputBase-root input {
      padding: 8px 14px;
  }
  .MuiChip-root {
    background-color: ${colors.brand.b3};
  }
`;

function NAIList({ zone }: IProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showAddNew, setShowAddNew] = useState(false);
  const [newNAIAdd, setNewNAIAdd] = React.useState([]);

  React.useEffect(() => {
    setNewNAIAdd([]);
    setShowAddNew(false);
  }, [zone]);

  const handleChange = (newValue: MuiChipsInputChip[]) => {
    if (zone.nais) {
      // @ts-ignore
      setNewNAIAdd(newValue);
    }
  };

  function handleCancel() {
    setNewNAIAdd([]);
    setShowAddNew(false);
  }

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['submit-nai'],
    // eslint-disable-next-line
    mutationFn: (nais: any) => Promise.all(nais.map((naiName: string) => api.post(apiURLs.nais.create, { name: naiName, zoneId: zone.id })))
      .then(() => {
        reset();
        toast.success(t('nai_added'));
        handleCancel();
      })
      .catch(handleCancel),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['zone-list'] }); },
  });

  function handleSubmit() {
    submit(newNAIAdd);
  }

  return (
    <Stack>
      {zone?.nais?.map((nai) => (
        <NAIListItem nai={nai} key={nai.id} />
      ))}
      {showAddNew ? (
        <Stack>
          <Chips
            value={newNAIAdd}
            onChange={handleChange}
            placeholder={t('nai_name_enter')}
            size="medium"
            fullWidth
            hideClearAll
          />
          <Stack flexDirection="row" mt="10px" gap={1}>
            <Button variant="contained" color="primary" size="small" onClick={() => handleSubmit()}>{t('save')}</Button>
            <Button variant="outlined" color="secondary" size="small" onClick={() => handleCancel()}>{t('cancel')}</Button>
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row">
          <AddButton onClick={() => setShowAddNew(true)} label={t('add_new')} />
        </Stack>
      )}
    </Stack>
  );
}

export default NAIList;
