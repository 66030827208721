import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import api, { apiURLs } from 'services/api';

interface Props {
  setFileUploadedStatus: (status: string) => void
  setTargets: (targets: any) => void
  // setTargets: (targets: Target[]) => void
}

const focusedStyle = {
  border: '1px solid #6D834A',
  backgroundColor: '#121E17',
};

const acceptStyle = {
  border: '1px solid #6D834A',
  backgroundColor: '#121E17',
};
const rejectStyle = {
  border: '1px solid #6D834A',
  backgroundColor: '#121E17',
};

function TargetSelectUploadFile({ setFileUploadedStatus, setTargets }: Props) {
  const { t } = useTranslation();

  const axiosConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

  const {
    mutate: submit,
  } = useMutation({
    mutationKey: ['submit-target'],
    mutationFn: (file: any) => (
      api
        .post(apiURLs.targets.import, file, axiosConfig)
        .then(({ data: { targets } }) => {
          setTargets(targets);
          setFileUploadedStatus('upload_success');
        })
        .catch(() => setFileUploadedStatus('upload_error'))
    ),
  });

  const handleUpload = (file: any) => {
    setFileUploadedStatus('upload_in-progress');

    const formData = new FormData();
    formData.append('file', file[0]);

    submit(formData);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    handleUpload(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: { 'text/csv': ['.scene'] } });

  const style = useMemo(() => ({
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  return (
    <div {...getRootProps({ style })} className="drop-file-container">
      <input {...getInputProps()} />
      <p>{t('drag_and_drop_file')}</p>
      <p className="sub-text">{t('upload_format')}</p>
    </div>
  );
}

export default TargetSelectUploadFile;
