import React from 'react';
import {
  Button, Dialog, Stack, TextareaAutosize, Typography, useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IMission } from 'types/mission';
import { colors } from 'theme';
import { useTranslation } from 'react-i18next';
import { missionStatus } from 'constants/mission';

interface IProps {
  open: boolean;
  mission: IMission;
  onClose: () => void;
  onSave: (status: string, report: string) => void;
  status?: string
  setStatus?: (status: string) => void
    title: string;
  subtitle: string;
}

function EndMissionConfirmation({
  open, mission, onClose, onSave, status, setStatus, title, subtitle,
}: IProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [report, setReport] = React.useState<string>('');

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      maxWidth="xl"
      PaperProps={{
        sx: {
          background: colors.grayscale.b4,
          borderRadius: '24px',
        },
      }}
    >
      <Stack p={3} minWidth="620px" height="100%">
        <Stack alignItems="center" flexDirection="row" mb={3}>
          <Stack flex={1} alignItems="center">
            <Typography color={colors.grayscale.g2}>{mission.name}</Typography>
          </Stack>
          <CloseIcon onClick={onClose} style={{ color: colors.grayscale.g2, flex: '0 0 24px' }} />
        </Stack>
        <Typography mb="12px" variant="h3" textAlign="center">{title}</Typography>
        <Typography mb={3} textAlign="center" color={colors.grayscale.g2}>{subtitle}</Typography>
        {status !== missionStatus.SUSPENDED && (
          <Stack flexDirection="row" justifyContent="center" gap="14px" mb={3}>
            <Button
              className="completed"
              variant={status === missionStatus.COMPLETED ? 'contained' : undefined}
              sx={{ border: `2px solid ${colors.system.g1}`, color: colors.system.g1 }}
              onClick={() => setStatus && setStatus(missionStatus.COMPLETED)}
            >
              {t(missionStatus.COMPLETED)}
            </Button>
            <Button
              color="error"
              className="not_completed"
              variant={status === missionStatus.NOT_COMPLETED ? 'contained' : undefined}
              sx={{ border: `2px solid ${colors.system.red}` }}
              onClick={() => setStatus && setStatus(missionStatus.NOT_COMPLETED)}
            >
              {t(missionStatus.NOT_COMPLETED)}
            </Button>
          </Stack>
        )}
        <TextareaAutosize
          placeholder={t('add_report')}
          maxRows={10}
          onChange={(e) => setReport(e.target.value)}
          value={report}
          style={{
            backgroundColor: 'transparent',
            border: `2px solid ${colors.grayscale.b5}`,
            fontSize: '14px',
            borderRadius: '20px',
            padding: '10px',
            color: colors.grayscale.g2,
            minHeight: '150px',
            fontFamily: 'e-Ukraine',
            marginBottom: '24px',
          }}
        />
        <Stack flexDirection="row" gap="14px" justifyContent="center">
          <Button variant="outlined" color="primary" onClick={onClose}>{t('cancel')}</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!status}
            onClick={() => onSave(status as string, report)}
          >
            {t('save')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

EndMissionConfirmation.defaultProps = {
  status: '',
  setStatus: () => {},
};

export default EndMissionConfirmation;
