import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material';
import { colors } from 'theme';

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    min-height: 62px;
  }
  .MuiChip-root {
    background: ${colors.brand.b3};
    height: 36px;
  }
`;

export default function CustomAutocomplete(props : any) {
  return <StyledAutocomplete {...props} />;
}
