import React from 'react';
import { Stack, Typography } from '@mui/material';

import { ITarget } from 'types/target';
import { IMission } from 'types/mission';

import TargetSign from '../../targets/components/targetSign';
import TargetCounts from '../../targets/components/targetCounts';

interface IProps {
  mission: IMission;
}

function MissionCardTarget({ mission }: IProps) {
  return (
    <Stack>
      {mission.target?.map((tg: ITarget) => (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" key={tg.id}>
          <Stack flexDirection="row" alignItems="center" gap="5px">
            <Typography variant="body2">{tg.name || tg.app6d_type}</Typography>
            <TargetSign sidc={tg.app6d_sidc} />
          </Stack>
          <TargetCounts
            photos={tg.images?.length}
            comments={tg.delta_comments?.length}
          />
        </Stack>
      ))}
    </Stack>
  );
}

export default MissionCardTarget;
