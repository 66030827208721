import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Stack, styled, TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { INAI } from 'types/zone';
import { colors } from 'theme';
import { getTextFieldErrorProps } from 'services/helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';
import { toast } from 'react-toastify';

interface IProps {
  nai: INAI
}

const IconButton = styled(Button)`
    padding: 2px 2px;
    color: ${colors.grayscale.g2};
    min-width: auto;
    border: none;
    &:hover {
        background-color: transparent;
    }
`;

function NAIListItem({ nai }: IProps) {
  const { t } = useTranslation();
  const inputRef = React.useRef(null);
  const queryClient = useQueryClient();

  const [isEditable, setEditable] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [naiName, setNAIName] = React.useState({ name: nai.name });

  React.useEffect(() => {
    setNAIName({ name: nai.name });
    setEditable(false);
  }, [nai]);

  function handleEdit() {
    setEditable(true);
    // @ts-ignore
    inputRef.current?.focus();
  }

  const {
    mutate: updateNAI, reset: resetUpdateNAI,
  } = useMutation({
    mutationKey: ['update-nai'],
    mutationFn: (c: any) => api.patch(apiURLs.nais.update(nai.id), c)
      .then(() => {
        resetUpdateNAI();
        setEditable(false);
      })
      .catch((err) => toast.error(t(err.response.data?.errorCode))),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['zone-list'] }); },
  });

  const {
    mutate: deleteNAI, reset: resetDeleteNAI,
  } = useMutation({
    mutationKey: ['delete-nai'],
    mutationFn: (c: any) => api.delete(apiURLs.nais.delete(nai.id), c)
      .then(() => {
        resetDeleteNAI();
        toast.success(t('nai_deleted'));
      })
      .catch((err) => toast.error(t(err.response.data?.errorCode))),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['zone-list'] }); },
  });

  return (
    <Stack key={nai.id} mb="12px">
      {isEditable
        ? (
          <Stack>
            <TextField
              size="small"
              placeholder={t('zone_name')}
              fullWidth
              onChange={(e) => setNAIName({ name: e.target.value })}
              value={naiName.name}
              {...getTextFieldErrorProps(errors, 'name', t)}
            />
            <Stack flexDirection="row" mt="10px" gap={1}>
              <Button variant="contained" color="primary" size="small" onClick={() => updateNAI(naiName)}>{t('save')}</Button>
              <Button variant="outlined" color="secondary" size="small" onClick={() => setEditable(false)}>{t('cancel')}</Button>
            </Stack>
          </Stack>
        )
        : (
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">{nai.name}</Typography>
            <Stack flexDirection="row" alignItems="center" gap="10px">
              <IconButton onClick={() => handleEdit()}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => deleteNAI(nai.id)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        )}
    </Stack>

  );
}

export default NAIListItem;
