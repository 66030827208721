import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { urls } from 'router';
import KHTable from 'components/khTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { ILocation } from 'types/crew';

interface Props {
  locationList: ILocation[] | undefined;
  isLoading?: boolean;
  deleteItem: (id: string) => Promise<void>;
}

function LocationTable({ locationList, isLoading, deleteItem }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <KHTable>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body2">#</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('name')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('control_point_distance')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('details')}</Typography>
          </TableCell>
          <TableCell width="25%">
            <Typography variant="body2">ID</Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && Array.from(Array(5)).map((i) => (
          <TableRow key={i}>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
          </TableRow>
        ))}
        {!isLoading && locationList?.map((location, i) => (
          <TableRow
            key={location.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            hover
          >
            <TableCell onClick={() => navigate(urls.admin.location.edit(location.id))}>
              <Typography variant="body1">{i + 1}</Typography>
            </TableCell>
            <TableCell onClick={() => navigate(urls.admin.location.edit(location.id))}>
              <Typography variant="body1">{location.name}</Typography>
            </TableCell>
            <TableCell onClick={() => navigate(urls.admin.location.edit(location.id))}>
              <Typography variant="body1">{(location.control_point_distance || 0) / 1000} км</Typography>
            </TableCell>
            <TableCell onClick={() => navigate(urls.admin.location.edit(location.id))}>
              <Typography variant="body1">{location.description}</Typography>
            </TableCell>
            <TableCell component="th" scope="row" onClick={() => navigate(urls.admin.location.edit(location.id))}>
              <Typography variant="body1">{location.id}</Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton color="secondary" className="action-icon" onClick={() => navigate(urls.admin.location.edit(location.id))}><EditIcon /></IconButton>
              <IconButton color="secondary" className="action-icon" onClick={() => location.id && deleteItem(location.id)}><DeleteIcon /></IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </KHTable>
  );
}

LocationTable.defaultProps = {
  isLoading: false,
};

export default LocationTable;
