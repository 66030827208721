import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Container, Stack } from '@mui/material';

import { IMissionDto } from 'types/mission';
import { ICrew, IGetCrewListResponse } from 'types/crew';
import { IGetTargetListResponse, ITarget } from 'types/target';
import { BaseFilters } from 'services/model';
import { urls } from 'router';
import api, { apiURLs } from 'services/api';
import { dayjs } from 'services/dayjs';
import FakeModal from 'components/fakeModal';
import { IGetZoneListResponse, IZone } from 'types/zone';

import MissionForm from '../components/missionForm';
import TargetSelect from '../components/targetSelect';
import { IError } from '../../../services/helpers';

const defaultMission: IMissionDto = {
  name: '',
  type: 'recon',
  due_date: dayjs(),
  status: 'planned',
  crew: null,
  report: '',
  target: [],
  ammo: [],
  description: '',
};

function EditMission() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const search = new URLSearchParams(location.search).get('search');
  const filters = { page: 1, limit: 100, search } as BaseFilters;
  const [mission, setMission] = useState<IMissionDto>(defaultMission);
  const [showTargetsSelect, setShowTargetsSelect] = useState(false);
  const [crewsList, setCrewsList] = useState<ICrew[]>();
  const [targetsList, setTargetsList] = useState<ITarget[]>();
  const [zonesList, setZonesList] = useState<IZone[]>([]);
  const [errors, setErrors] = useState<IError>({});
  const {
    data: crews, isFetching: isFetchingCrews,
  } = useQuery({
    queryKey: ['crew-list'],
    queryFn: (): Promise<IGetCrewListResponse> => api.get(apiURLs.crews.list(null))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (crews?.results && !isFetchingCrews) setCrewsList(crews.results);
  }, [crews, isFetchingCrews]);

  const {
    data: missionDetails, isFetching: isFetchingMissionDetails,
  } = useQuery({
    queryKey: ['mission-details'],
    queryFn: (): Promise<IMissionDto> => api.get(apiURLs.missions.details(id))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const {
    data: targets, isFetching: isFetchingTargets,
  } = useQuery({
    queryKey: ['target-list', filters],
    queryFn: (): Promise<IGetTargetListResponse> => api.get(apiURLs.targets.list(filters))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (targets?.results && !isFetchingTargets) {
      const notSelectedTargets = targets.results.filter((mt) => (
        !mission.target.find((stm) => (
          mt.id === stm.id
        ))
      ));
      setTargetsList(notSelectedTargets);
    }
  }, [targets, isFetchingTargets, mission]);

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['update-mission'],
    mutationFn: (m: any) => (api.patch(apiURLs.missions.updateMission(id), m)
      .then(() => {
        reset();
        navigate(urls.missions.details(id));
        toast.success(t('mission_created_successfully'));
      })
      .catch((err) => toast.error(err.response.data?.error))
    ),
  });

  const prepareUpdateMissionDto = (m: IMissionDto) => ({
    ...m,
    target: m.target.map((target: ITarget) => target.id),
    crew: m.crew?.id,
    ammo: m.ammo?.map((a) => a.id),
  });

  const toggleTargetSelect = () => setShowTargetsSelect(!showTargetsSelect);

  const {
    data: zones, isFetching: isFetchingZones,
  } = useQuery({
    queryKey: ['zone-list'],
    queryFn: (): Promise<IGetZoneListResponse> => api.get(apiURLs.zones.list(null))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (zones?.results && !isFetchingZones) {
      const zonesListFromResponse = zones.results;
      setZonesList(zonesListFromResponse);

      const naiZones = zonesListFromResponse?.filter(({ id: zoneListId }) =>
        // eslint-disable-next-line
        mission?.nais?.map(({ zoneId }) => zoneId).includes(zoneListId)) || [];

      setMission({
        ...mission,
        zones: mission.zones || naiZones,
      });
    }
  }, [zones, isFetchingZones, mission.id]);

  useEffect(() => {
    if (missionDetails && !isFetchingMissionDetails) setMission(missionDetails);
  }, [missionDetails, isFetchingMissionDetails]);

  const onTargetClick = (target: ITarget) => {
    if (mission) {
      if (!mission.target.find((tg) => tg.id === target.id)) {
        setMission({ ...mission, target: [...mission.target, target] });
        setTargetsList((prevState) => prevState?.filter((tg) => tg.id !== target.id));
      } else {
        setMission({
          ...mission,
          target: mission.target.filter((tg) => tg.id !== target.id),
        });
        if (targetsList) setTargetsList([...targetsList, target]);
      }
    }
  };

  return (
    <Container maxWidth="xl">
      <FakeModal
        cancel={() => navigate(urls.missions.landing)}
        title={t('edit_mission')}
        onSubmit={() => submit(prepareUpdateMissionDto(mission))}
      >
        <Stack flexDirection="row" gap={2}>
          <Stack gap={1} flex={1}>
            <MissionForm
              showTargetsSelect={showTargetsSelect}
              setShowTargetsSelect={() => toggleTargetSelect()}
              mission={mission}
              setMission={setMission}
              crews={crewsList}
              zones={zonesList}
              onTargetClick={onTargetClick}
              errors={errors}
            />
          </Stack>
          {showTargetsSelect ? (
            <TargetSelect
              onClose={setShowTargetsSelect}
              targets={targetsList}
              onTargetClick={onTargetClick}
            />
          ) : null}
        </Stack>
      </FakeModal>
    </Container>
  );
}

export default EditMission;
