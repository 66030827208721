import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button, Divider, Grid, Skeleton, Stack, Typography,
} from '@mui/material';
import { ITarget } from 'types/target';
import api, { apiURLs } from 'services/api';
import { urls } from 'router';
import { colors } from 'theme';
import defaultTarget from 'defaults/target';
import mission from 'defaults/mission';
import { getDefaultDate } from 'services/dayjs';
import FavoritesButton from 'components/buttons/favoriteButton';
import BackArrowButton from 'components/buttons/backArrowButton';
import { DELTA_LAYER_ID, TARGET_STATUS } from 'constants/target';
import TargetSign from '../components/targetSign';
import TargetImagesGallery from '../components/targetImagesGallery';
import TargetMissions from '../components/targetMissions';
import ArchiveButton from '../../../components/buttons/archiveButton';

function TargetDetails() {
  const { id = '' } = useParams();
  const [target, setTarget] = React.useState<ITarget>(defaultTarget);
  const [favorite, setFavorite] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    data: targetDetails, isFetching: isFetchingTargetDetails,
  } = useQuery({
    queryKey: ['target-details'],
    queryFn: (): Promise<ITarget> => api.get(apiURLs.targets.details(id))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const archiveTarget = useMutation({
    mutationFn: (targetId: string) => api.patch(apiURLs.targets.archive(targetId)),
    onSuccess: () => {
      toast.success(t('target_archived_successfully'));
      queryClient.invalidateQueries({ queryKey: ['target-details'] });
    },
    onError: () => toast.error('target_archivation_error'),
  });

  React.useEffect(() => {
    if (targetDetails && !isFetchingTargetDetails) setTarget(targetDetails);
  }, [targetDetails, isFetchingTargetDetails]);

  if (isFetchingTargetDetails) return <Skeleton height={50} />;

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" mb="20px">
        <BackArrowButton path={urls.targets.landing} label={t('all_targets')} />
        <Stack flexDirection="row" gap={1}>
          {targetDetails?.status !== TARGET_STATUS.ARCHIVED ? (
            <ArchiveButton onClick={() => archiveTarget.mutate(id)} />
          ) : (
            <Button variant="contained" onClick={() => archiveTarget.mutate(id)}>{t('unarchive')}</Button>
          )}
        </Stack>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" mb="26px">
        <Typography variant="h1">{target.name}</Typography>
        <FavoritesButton checked={favorite} setChecked={setFavorite} />
      </Stack>
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Grid container mb={1}>
            <Grid item xs={12} mb="20px">
              <Typography variant="h2">{t('details')}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('type')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Stack flexDirection="row" alignItems="center" gap="13px">
                <Typography variant="body1">{target.app6d_type}</Typography>
                <TargetSign sidc={target.app6d_sidc} width={24} height={24} />
              </Stack>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('quantity')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{target.quantity}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('name_in_delta')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{target.name || '-'}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('source')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{target.platform_type || '-'}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('reliability_source')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{target.reliability_credibility || '-'}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('coordinates')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{target.coordinates || '-'}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('observation_time')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDefaultDate(target.observation_datetime) || '-'}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '24px' }} />
          <Grid xs={6} item>
            <Grid container mb={1}>
              <Grid item xs={12}>
                <Typography variant="body1" color={colors.grayscale.g2}>{t('records')}</Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography variant="body1">{mission.comments || '-'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={colors.grayscale.g2}>{t('delta_comment')}</Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography variant="body1">{mission.delta_comments || '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '24px' }} />
          <Stack mb={2}>
            <Button
              variant="outlined"
              color="secondary"
              component="a"
              href={`https://delta.mil.gov.ua/monitor/map?layerId=${DELTA_LAYER_ID}&objectId=${target.uuid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('open_in_delta')}
            </Button>
          </Stack>
          <TargetImagesGallery images={target.images} />
        </Grid>
        <Grid item xs={6}>
          <TargetMissions target={target} />
        </Grid>
      </Grid>
    </>
  );
}

export default TargetDetails;
