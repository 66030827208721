/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import MissionHistoryDialog from 'features/missions/components/missionHistoryDialog';
import { formatDate } from 'services/dayjs';
import { IMission } from 'types/mission';

interface IProps {
    mission: IMission | undefined;
}

const AuditBox = styled(Box)`
  margin-bottom: 25px;
`;

export default function MissionAudit({ mission }: IProps) {
  const [isHistoryVisible, setHistoryVisibility] = useState(false);

  const manageHistoryVisibility = useCallback(() => {
    setHistoryVisibility(!isHistoryVisible);
  }, [isHistoryVisible]);

  if (!mission) {
    return null;
  }

  return (
    <>
      {
            isHistoryVisible
            && (
            <MissionHistoryDialog
              manageVisibility={manageHistoryVisibility}
              isVisible={isHistoryVisible}
              missionId={mission.id}
            />
            )
        }
      <AuditBox>
        <Typography onClick={() => setHistoryVisibility(true)} variant="body1" style={{ color: '#757575' }}>
          {'Змінено о '}
          {`${formatDate(mission?.updated_at as string)}. `}
          <a style={{ cursor: 'pointer' }}>Дивитись більше</a>
        </Typography>
      </AuditBox>
    </>
  );
}
