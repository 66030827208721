import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, styled } from '@mui/material';

interface IProps {
  onClick: () => void
  disabled?: boolean
}

const KHDeleteButton = styled(Button)`
  padding: 9px 20px;
`;

function DeleteButton({ onClick, disabled }: IProps) {
  return (
    <KHDeleteButton variant="outlined" color="secondary" onClick={onClick} disabled={disabled}><DeleteIcon /></KHDeleteButton>
  );
}

DeleteButton.defaultProps = {
  disabled: false,
};

export default DeleteButton;
