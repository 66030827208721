import React from 'react';
import {
  Card, CardContent, Stack, Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { IMission } from 'types/mission';
import { getTime } from 'services/dayjs';
import { colors } from 'theme';
import UavTypeIcon from 'components/uavTypeIcon';
import MissionTypeIcon from 'components/missionTypeIcon';

import { first } from 'lodash';
import MissionCardTarget from './missionCardTarget';
import MissionCardZone from './missionCardZone';

interface Props {
  mission: IMission
  selectMission: (mission: IMission) => void
  selectedMissionId?: string
}

function MissionCard(
  {
    mission,
    selectMission,
    selectedMissionId,
  }: Props,
) {
  return (
    <Stack mb={1}>
      <Card onClick={() => selectMission(mission)} variant={selectedMissionId === mission.id ? 'outlined' : 'elevation'}>
        <CardContent sx={{ padding: 2, ':last-child': { padding: 2, cursor: 'pointer' } }}>
          <Stack alignContent="space-between" gap="7px">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack display="flex" gap="4px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography mr="3px">{mission.mission_key || mission.name}</Typography>
                <MissionTypeIcon type={mission.type} />
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap="5px">
                <UavTypeIcon type={first(mission?.crew?.uav)?.type || ''} />
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap="4px">
                  { mission.crew?.status === 'not_ready' ? (
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap="4px">
                      <Typography variant="body2" color={colors.system.red}>{mission?.crew?.name}</Typography>
                      <FiberManualRecordIcon fontSize="small" style={{ color: colors.system.red, fontSize: '.75rem' }} />
                    </Stack>
                  ) : (
                    <Typography variant="body2">{mission?.crew?.name}</Typography>
                  ) }
                  <Typography variant="body2">•</Typography>
                  {mission?.crew?.uav?.map((crew) => (
                    <Typography variant="body2" key={crew.id}>{crew.name}</Typography>
                  ))}
                </Stack>
              </Stack>
            </Stack>
            { mission.type === 'strike' && <MissionCardTarget mission={mission} /> }
            { mission.type === 'recon' && <MissionCardZone mission={mission} /> }
            <Stack flexDirection="row" gap="6px" alignItems="center">
              <AccessTimeIcon fontSize="small" style={{ color: colors.grayscale.g2 }} />
              <Typography variant="body2">{getTime(mission.due_date)}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

MissionCard.defaultProps = {
  selectedMissionId: '',
};

export default MissionCard;
