import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('uk');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

function getDefaultDate(date?: string) {
  return dayjs(date).format('DD MMMM YYYY HH:mm');
}

function getDayDate(date?: string) {
  return dayjs(date).format('DD MMMM YYYY');
}

function getTime(date?: string) {
  return dayjs(date).format('HH:mm');
}

function formatDate(isoString: string) {
  const date = new Date(isoString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

export {
  getDefaultDate,
  getDayDate,
  getTime,
  dayjs,
  formatDate,
};
