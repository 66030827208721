import React from 'react';
import {
  AppBar, Box, Container, Hidden, Stack, Toolbar,
} from '@mui/material';
import { NavigationItem } from 'components/header/navigation';
import { Link, useLocation } from 'react-router-dom';
import { colors } from 'theme';

import './header.css';
import { CSSProperties } from 'styled-components';
import User from './user';
import MobileMenu from './mobileMenu';
import TopNavigation from './topNavigation';

interface Props {
    navigationItems: NavigationItem[];
    statusSwitcher?: React.ReactNode;
}

const headerStyles = {
  borderBottom: `1px solid ${colors.grayscale.b4}`,
  borderRadius: 0,
  position: 'relative',
  zIndex: 1301,
} as CSSProperties;

function Header({ navigationItems, statusSwitcher }: Props) {
  const { pathname } = useLocation();

  return (
    <AppBar elevation={0} position="sticky" style={headerStyles}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box justifyContent="space-between" flexDirection="row" display="flex" width="100%" alignItems="center">
            <Stack direction="row" gap={5} alignItems="center">
              <Link to="/" className="logoLink">
                <img src="/logo-white.svg" width="42" alt="Khartiia STS" />
              </Link>
              <Hidden mdDown>
                {statusSwitcher}
              </Hidden>
            </Stack>
            <Hidden mdUp>
              {statusSwitcher}
            </Hidden>
            <Hidden mdDown>
              <TopNavigation navigationItems={navigationItems} pathname={pathname} orientation="horizontal" />
            </Hidden>
            <Hidden mdDown>
              <Box display="flex" flexDirection="row" justifyContent="right">
                <User />
              </Box>
            </Hidden>
            <Hidden mdUp>
              <MobileMenu navigationItems={navigationItems} />
            </Hidden>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

Header.defaultProps = {
  statusSwitcher: null,
};

export default Header;
