import React from 'react';
import { Box, styled } from '@mui/material';

interface Props {
  backgroundColor: string
  color?: string
  children: React.ReactNode
  width?: string
  height?: string
}

const StyledIcon = styled(Box)`
  width: 30px;
  height: 22px;
  position: relative;
  border-radius: 100px;
  padding: 2px 3px;

  svg {
    position: absolute;
    width: 18px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default function IconFrame(
  {
    color,
    backgroundColor,
    children,
    width,
    height,
  }: Props,
) {
  return (
    <StyledIcon
      sx={{
        color,
        backgroundColor,
        width,
        height,
      }}
    >
      {children}
    </StyledIcon>
  );
}

IconFrame.defaultProps = {
  color: 'white',
  width: '30px',
  height: '22px',
};
