import { IAmmunition, ICrew } from 'types/crew';
import { ITarget } from 'types/target';
import { IGetZone, INAI, IZone } from 'types/zone';
import { Dayjs } from 'dayjs';

export type TMissionStatus = 'planned' | 'in_progress' | 'completed' | 'not_completed' | 'suspended';

export interface IReport {
  id: string
  text: string
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}

export interface IMission {
  id: string
  name: string
  type: string
  due_date: string
  status: TMissionStatus
  reports?: IReport[]
  crew: ICrew
  target: ITarget[]
  ammo?: IAmmunition[]
  checklist?: string[] | null
  comments: string
  delta_comments: string
  finished: boolean
  created_at: string
  updated_at?: string
  description?: string
  nais?: INAI[]
  zones?: IZone[]
  mission_key?: string
  work_area?: string
}

export interface IMissionAudit {
  status: {
    previous: TMissionStatus
    current: TMissionStatus
  }
  id: string
  updated_at: {
    previous: string
    current: string
  }
  updated_by: {
    previous: any
    current: any
  }
  due_date: {
    previous: string
    current: string
  }
}

export interface IMissionDto {
  id?: string
  name: string
  type: string
  due_date: Dayjs
  status: TMissionStatus
  crew: ICrew | null
  target: ITarget[]
  ammo?: IAmmunition[]
  zones?: IGetZone[]
  nais?: INAI[]
  report?: string
  description?: string
  work_area?: string
}

export interface IMissionsFilterDto {
  type?: string
  due_date?: string
  status?: TMissionStatus
  crew?: ICrew | null
  target?: ITarget[]
  ammo?: IAmmunition[]
  zones?: IGetZone[]
  nais?: INAI[]
  work_area?: string
  finished?: boolean
}

export const defaultFilters = {
  type: '',
  due_date: undefined,
  status: undefined,
  crew: null,
  target: [],
  ammo: [],
  zones: [],
  nais: [],
  work_area: '',
  finished: false,
};

export interface IGetMissionListResponse {
  pageCount: number
  total: number
  results: IMission[]
}
