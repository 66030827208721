import React from 'react';
import {
  Alert, Box, Button, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorIcon from 'assets/icons/error';
import HeaderLayout from '../header/headerLayout';

interface State {
  hasError: boolean;
  error: string | null;
}

interface Props {
  children: React.ReactNode;
}

interface ErrorProps {
  resetError: Function
    error: string | null
}

function ErrorMessage({ resetError, error }: ErrorProps) {
  const { t } = useTranslation();
  return (
    <Box m={5}>
      <Box flexDirection="column" justifyContent="center" alignItems="center" display="flex" gap="16px" mb="24px">
        <ErrorIcon />
        <Typography variant="h2">{t('something_went_wrong')}</Typography>
      </Box>
      <Box mt={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
      <Stack direction="row" gap={2} mt={5} alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            resetError();
          }}
        >{t('refresh_page')}
        </Button>
      </Stack>
    </Box>
  );
}

export default class ErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line no-useless-constructor
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({ hasError: true, error: error.message });
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;

    return hasError ? (
      <HeaderLayout>
        <ErrorMessage
          resetError={() => this.setState({ hasError: false, error: null })}
          error={error}
        />
      </HeaderLayout>
    ) : children;
  }
}
