import React from 'react';
import { Button, styled } from '@mui/material';

import { colors } from 'theme';

export const StatusLabel = styled(Button)`
  padding: 0 8px;
  border: none;
  color: ${colors.grayscale.g2};
  background-color: ${colors.grayscale.b5};
  &:hover {
    background-color: ${colors.grayscale.b5};
  };
  &:disabled {
    color: ${colors.grayscale.g2};
  };
  &.in_progress {
    color: #689CFF;
    background-color: #233756;
  };
  &.operating {
    color: #47E657;
    background-color: #2F5623;
  };
  &.planned {
    color: #AFAFAF;
    background-color: #454545;
  };
  &.unplanned {
    color: #AFAFAF;
    background-color: #454545;
  };
  &.affected {
    color: #47E657;
    background-color: #2F5623;
  };
  &.completed {
    color: #47E657;
    background-color: #2F5623;
  };
  &.ready {
      color: #689CFF;
      background-color: #233756;
  };
  &.not_affected {
    color: #FFB443;
    background-color: #564416;
  };
  &.suspended {
    color: #FFB443;
    background-color: #564416;
  };
  &.not_ready {
    color: #FF7D55;
    background-color: #562F23;
  };
  &.not_completed {
    color: #FF7D55;
    background-color: #562F23;
  };
`;

export default StatusLabel;
