import React from 'react';
import { IMission } from 'types/mission';
import { IFavorites } from 'types/favorites';
import { useTranslation } from 'react-i18next';
import MissionGroup from './missionGroup';

interface IProps {
  missions: IMission[]
  favorites: IFavorites[]
  selectedMission?: IMission | null
  selectMissionAndScrollTop: (mission: IMission) => void
  isFetching: boolean
}

function MissionByFavorite(
  {
    missions,
    favorites,
    selectedMission,
    selectMissionAndScrollTop,
    isFetching,
  }: IProps,
) {
  const filterMissionsFavorite = (missionsList: IMission[] | undefined) => (
    missionsList
      ?.filter((m) => !!favorites.find((f) => f.itemId === m.id))
  );

  const { t } = useTranslation();

  return (
    <MissionGroup
      name={t('starred')}
      missions={filterMissionsFavorite(missions)}
      selectMission={selectMissionAndScrollTop}
      selectedMissionId={selectedMission?.id}
      isFetching={isFetching}
      expanded
    />
  );
}

MissionByFavorite.defaultProps = {
  selectedMission: null,
};

export default MissionByFavorite;
