import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { urls } from 'router';
import { useQuery } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';
import { Box, Button, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { BaseFilters } from 'services/model';
import UsersTable from '../components/usersTable';
import { IGetUsersResponse, User } from '../services/model';
import './user.css';

function AdminUsersPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const search = new URLSearchParams(location.search).get('search');
  const filters = { page: 1, limit: 10, search } as BaseFilters;
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState<User[]>([]);

  const {
    data, isFetching, isLoading, refetch,
  } = useQuery({
    queryKey: ['users-list', filters],
    queryFn: (): Promise<IGetUsersResponse> => api.get(apiURLs.admin.users.list(filters))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (data?.results && currentPage === 1) {
      setResults(data.results);
    }
  }, [data]);

  const loadMore = async () => {
    setCurrentPage(currentPage + 1);
    try {
      filters.page = currentPage + 1;
      const previousData = results;
      const newResults = await refetch();
      setResults([...previousData || [], ...newResults.data?.results || []]);
    } catch (err) {
      toast.error((err as Error).message);
    }
  };

  return (
    <>
      <PageHeader createLabel={t('create_user')} title={t('users')} onCreate={() => navigate(urls.admin.users.create)} />
      <Box sx={{ overflowX: 'auto' }}>
        <UsersTable rows={results} isLoading={isLoading} />
      </Box>
      <Stack direction="row" justifyContent="center" className="footer-container">
        <Button variant="outlined" color="primary" onClick={loadMore} disabled={isFetching || currentPage >= (data?.pageCount || 0)}>{t('loadMore')}</Button>
      </Stack>
    </>
  );
}

export default AdminUsersPage;
