import React from 'react';
import {
  Box, Button, Card, Divider, IconButton, Link, Stack, styled, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { colors } from 'theme';
import { ITarget } from 'types/target';
import { urls } from 'router';
import TargetSign from './targetSign';
import TargetImagesGallery from './targetImagesGallery';
import TargetDetailsInfo from './targetDetailsInfo';

import './targetDetails.css';
import { DELTA_LAYER_ID, TARGET_STATUS } from '../../../constants/target';
import TargetMissions from './targetMissions';
import ArchiveButton from '../../../components/buttons/archiveButton';
import Chip from '../../../components/chip';

interface IProps {
  target: ITarget
  setSelectedTarget: (id: string | null) => void
  onArchive: (id: string, isArchiving: boolean) => void
}

const DetailsCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px',
  border: 'none',
  backgroundColor: colors.grayscale.b2,
}));

function TargetDetails({ target, setSelectedTarget, onArchive }: IProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={1} flex={1}>
      <DetailsCard>
        <Stack flexDirection="row" justifyItems="center" justifyContent="space-between" mb={2}>
          <IconButton aria-label="close" onClick={() => setSelectedTarget(null)} style={{ padding: '0', color: colors.grayscale.g2 }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="body2" mb={1}>{t('target_details_header')}</Typography>
            <Stack direction="row" gap={2}>
              <Link href={urls.targets.details(target.id)}>
                <Typography variant="h1" mb={2}>{target.name || target.app6d_type}</Typography>
              </Link>
              {target.status === TARGET_STATUS.ARCHIVED && <Chip label={t('target_archived')} size="small" variant="outlined" />}
            </Stack>
          </Stack>
          <Stack>
            <TargetSign sidc={target.app6d_sidc} width={46} height={46} />
          </Stack>
        </Stack>
        {target.status !== TARGET_STATUS.ARCHIVED ? (
          <Box>
            <ArchiveButton onClick={() => onArchive(target.id, true)} />
          </Box>
        ) : (
          <Button variant="contained" onClick={() => onArchive(target.id, false)}>{t('unarchive')}</Button>
        )}
        <Divider sx={{ mb: 2, mt: 2 }} />
        <TargetDetailsInfo target={target} />
        <Stack mb={4}>
          <Button
            variant="outlined"
            color="secondary"
            component="a"
            href={`https://delta.mil.gov.ua/monitor/map?layerId=${DELTA_LAYER_ID}&objectId=${target.uuid}`}
            target="_blank"
            rel="noopener noreferrer"
          >

            {t('open_in_delta')}
          </Button>
        </Stack>
        <TargetMissions target={target} />
        <TargetImagesGallery images={target.images} />
        <Divider style={{ marginBottom: '16px' }} />
        <Button size="large" variant="contained" color="primary" onClick={() => setSelectedTarget(null)} disabled={false}>{t('hide_details')}</Button>
      </DetailsCard>
    </Stack>
  );
}

export default TargetDetails;
