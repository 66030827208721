import React from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { darkTheme } from './theme';
import { store } from './store';
import { AuthProvider } from './features/login/authProvider';
import Router from './router';
import 'react-toastify/dist/ReactToastify.min.css';

import 'dayjs/locale/de';
import ErrorBoundary from './components/errorBoundary/errorBoundary';
import useWebSockets, { messageTypesEnum } from './services/useWebSockets';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useWebSockets(
    queryClient,
    [
      messageTypesEnum.MISSION_STATUS_UPDATE,
      messageTypesEnum.CREW_STATUS_UPDATE,
      messageTypesEnum.TARGET_STATUS_UPDATE,
      messageTypesEnum.MISSION_CREATED,
    ],
    () => queryClient.invalidateQueries({ queryKey: ['counts'] }),
  );

  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
                  <ErrorBoundary>
                    <Router />
                  </ErrorBoundary>
                </LocalizationProvider>
              </AuthProvider>
            </QueryClientProvider>
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
