import React from 'react';

import { colors } from 'theme';
import Footer from '../footer/footer';

interface HeaderLayoutProps {
  children: React.ReactNode;
}

function CreateLayout({ children }: HeaderLayoutProps) {
  return (
    <div style={{ backgroundColor: colors.grayscale.b3, minHeight: '100vh', paddingBottom: '80px' }}>
      {children}
      <Footer />
    </div>
  );
}

export default CreateLayout;
