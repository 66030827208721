import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  Button, Dialog, Hidden, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { defaultFilters, IGetMissionListResponse, IMission } from 'types/mission';
import { IFavorites, IFavoriteToggle } from 'types/favorites';
import api, { apiURLs } from 'services/api';
import { urls } from 'router';
import PageHeader from 'components/pageHeader';
import GroupBy from 'components/groupBy';
import defaultFavorite from 'defaults/favorite';
import { colors } from 'theme';

import { Transition } from 'components/header/mobileMenu';
import dayjs from 'dayjs';
import MissionDetailsSidebar from '../components/missionDetailsSidebar';
import MissionByStatus from '../components/missionByStatus';
import MissionByCrew from '../components/missionByCrew';
import MissionByFavorite from '../components/missionByFavorite';
import MissionByFinished from '../components/missionByFinished';
import { deepEqual, extendMissionsWithZones, mergeArraysById } from './utils';
import MissionFilters from '../components/missionFilters';

const groupByItems = ['status', 'crew', 'starred', 'finished'];

const getFilters = () => {
  const lsFilters = localStorage.getItem('filters');
  if (lsFilters) {
    return JSON.parse(lsFilters);
  }
  return defaultFilters;
};

function Missions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const groupParam = searchParams.get('group') || groupByItems[0];
  const [groupedBy, setGroupedBy] = useState(groupParam);
  const isGroupedByFinished = () => groupedBy === 'finished';
  const isGroupedByStarred = () => groupedBy === 'starred';
  const defaultFiltersState = { ...getFilters(), groupedBy };
  const [filters, setFilters] = useState(defaultFiltersState);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [missions, setMissions] = useState<IMission[]>([]);
  const [favorites, setFavorites] = useState<IFavorites[]>([defaultFavorite]);
  const [selectedMissionId, setSelectedMissionId] = useState<string | null>();
  const [isFiltersVisible, setFilterVisibility] = useState<boolean>(false);
  const [isFiltersApplied, setFiltersAppliedFlag] = useState<boolean>(false);

  const {
    data: zonesData = {},
  } = useQuery({
    staleTime: 5 * 60 * 1000,
    queryKey: ['zone-list'],
    queryFn: (): Promise<any> => api.get(apiURLs.zones.list(null))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const {
    data: crewsData = {},
  } = useQuery({
    queryKey: ['crew-list'],
    queryFn: (): Promise<any> => api.get(apiURLs.crews.list(null))
      .then((res) => res.data),
  });

  const [page, setPage] = useState(1);
  const limit = isGroupedByFinished() || isGroupedByStarred() ? 10 : 100;

  const [fetchedMissions, setFetchedMissions] = useState([]);

  const getFiltersWithUTCDate = (f: any) => {
    if (!f?.due_date) {
      return f;
    }
    return { ...f, due_date: dayjs(f.due_date).utc() };
  };

  const {
    data: fetchedMissionsData, isFetching, refetch,
  } = useQuery({
    queryKey: ['mission-list', page],
    // eslint-disable-next-line
    queryFn: (): Promise<IGetMissionListResponse> => api.get(apiURLs.missions.list(search, page, limit, getFiltersWithUTCDate(filters)))
      .then((res) => {
        const results = mergeArraysById([...fetchedMissions, ...res.data.results]);

        return {
          ...res.data,
          results,
          fetchedAt: new Date(),
        };
      })
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const applyFilters = () => {
    setFetchedMissions([]);
    setPage(1);
    setFiltersAppliedFlag(filters && !deepEqual(filters, defaultFilters));

    // TODO: check why it works more stable with setTimeout
    setTimeout(refetch, 100);
  };

  useEffect(() => {
    applyFilters();
  }, [search, filters.groupedBy]);

  useEffect(() => {
    if (fetchedMissionsData?.results) {
      setFetchedMissions(fetchedMissionsData?.results as any);
    }
  }, [fetchedMissionsData]);

  useEffect(() => {
    if (groupParam) {
      setGroupedBy(groupParam);
    }
  }, [groupParam]);

  useEffect(() => {
    if (fetchedMissions && !isFetching) {
      setMissions(extendMissionsWithZones(fetchedMissions, zonesData.results));
    }
  }, [fetchedMissions, zonesData.results, crewsData.results, isFetching]);

  useEffect(() => {
    setFilters({ ...filters, groupedBy });
  }, [groupedBy]);

  const {
    data: favoritesList, isFetching: isFavoritesListFetching,
  } = useQuery({
    queryKey: ['favourite-list'],
    queryFn: (): Promise<IFavorites[]> => api.get(apiURLs.favorites.list())
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (favoritesList && !isFavoritesListFetching) {
      setFavorites(favoritesList);
    }
  }, [favoritesList, isFavoritesListFetching]);

  useEffect(() => {
    const lsFilters = localStorage.getItem('filters');
    if (lsFilters) {
      setFiltersAppliedFlag(true);
    } else {
      setFiltersAppliedFlag(false);
    }
  }, [filters]);

  const {
    mutate: toggleFavorite,
  } = useMutation({
    mutationKey: ['submit-favorite'],
    mutationFn: (m: IFavoriteToggle) => (api.post(apiURLs.favorites.toggle(), m)
      .then(({ data: fav }) => fav)
      .catch((err) => toast.error(err.response.data?.error))
    ),
    onSuccess: (favorite: IFavorites, variables: any) => queryClient.setQueryData(['favourite-list'], (f: IFavorites[]) => {
      if (favorite.itemId) {
        toast.success(t('added_to_favorites'));
        return [...f, favorite];
      }
      toast.success(t('removed_from_favorites'));
      return f.filter((c) => c.itemId !== variables.itemId);
    }),
  });

  const selectMissionAndScrollTop = (mission: IMission) => {
    if (selectedMissionId === mission.id) {
      setSelectedMissionId(null);
    } else {
      setSelectedMissionId(mission.id);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  function onGroupByChange(group: string) {
    setGroupedBy(group);
    setSelectedMissionId(null);
    searchParams.set('group', group);
    setSearchParams(searchParams);
  }

  function isFavorite(id: string) {
    return !!favorites.find((favorite: IFavorites) => favorite.itemId === id);
  }

  function onFavoriteClick(id: string) {
    toggleFavorite({
      itemId: id,
      itemType: 'mission',
    });
  }

  const onManageFilters = () => {
    setFilterVisibility(!isFiltersVisible);
  };

  const selectedMission = missions.find((m: IMission) => m.id === selectedMissionId);

  const loadMore = async () => {
    setPage(page + 1);
  };

  return (
    <>
      <PageHeader title={t('missions')} onCreate={() => navigate(urls.missions.create, { state: { zones: zonesData.results } })} createLabel={t('createMission')} showDatePicker />
      <Stack flexDirection="row" justifyContent="space-between" mb={1}>
        <GroupBy
          items={groupByItems}
          selected={groupedBy}
          onChange={(group: string) => onGroupByChange(group)}
        />
        <Button
          onClick={onManageFilters}
          variant="contained"
          color="primary"
          size="small"
        >
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap="4px">
            {isFiltersApplied ? <FiberManualRecordIcon fontSize="small" style={{ color: colors.system.red, fontSize: '.75rem' }} /> : null}
            {t('filters')}
          </Stack>
        </Button>
      </Stack>
      <MissionFilters
        filters={filters}
        // eslint-disable-next-line
        setFilters={(changedFilters) => setFilters({ ...changedFilters, groupedBy })}
        zones={zonesData.results}
        crews={crewsData.results}
        isFiltersVisible={isFiltersVisible}
        submitFilters={applyFilters}
      />
      <Stack flexDirection="row" gap={2}>
        <Stack gap={1} flex={1}>
          {groupedBy === 'status' && (
            <MissionByStatus
              missions={missions}
              selectedMission={selectedMission}
              selectMissionAndScrollTop={selectMissionAndScrollTop}
              isFetching={isFetching}
            />
          )}
          {groupedBy === 'crew' && (
            <MissionByCrew
              missions={missions}
              selectedMission={selectedMission}
              selectMissionAndScrollTop={selectMissionAndScrollTop}
              isFetching={isFetching}
            />
          )}
          {isGroupedByStarred() && (
            <MissionByFavorite
              missions={missions}
              favorites={favorites}
              selectedMission={selectedMission}
              selectMissionAndScrollTop={selectMissionAndScrollTop}
              isFetching={isFetching}
            />
          )}
          {isGroupedByFinished() && (
            <MissionByFinished
              missions={missions}
              selectedMission={selectedMission}
              selectMissionAndScrollTop={selectMissionAndScrollTop}
              isFetching={false}
            />
          )}
        </Stack>
        {selectedMission && (
          <>
            <Hidden mdDown>
              <MissionDetailsSidebar
                groupedBy={groupedBy}
                mission={selectedMission}
                isFavorite={(id) => isFavorite(id)}
                onFavoriteClick={(id) => onFavoriteClick(id)}
                setSelectedMission={setSelectedMissionId}
              />
            </Hidden>
            <Hidden mdUp>
              <Dialog
                open={!!selectedMission}
                TransitionComponent={Transition}
                fullScreen
                sx={{
                  marginTop: '30px',
                  '& .MuiDialog-paper': {
                    borderRadius: '20px',
                  },
                }}
              >
                <MissionDetailsSidebar
                  groupedBy={groupedBy}
                  mission={selectedMission}
                  isFavorite={(id) => isFavorite(id)}
                  onFavoriteClick={(id) => onFavoriteClick(id)}
                  setSelectedMission={setSelectedMissionId}
                />
              </Dialog>
            </Hidden>
          </>
        )}
      </Stack>
      {
        (isGroupedByFinished() || isGroupedByStarred())
        && (
          <Stack direction="row" justifyContent="center" style={{ marginTop: 20 }}>
            <Button variant="outlined" color="primary" onClick={loadMore} disabled={isFetching || page >= (fetchedMissionsData?.pageCount || 0)} className="footer-button">{t('loadMore')}</Button>
          </Stack>
        )
      }
    </>
  );
}

export default Missions;
