import { ITarget } from 'types/target';
import defaultMission from './mission';

const defaultTarget: ITarget = {
  uuid: '',
  app6d_sidc: '',
  app6d_type: '',
  closest_settlement: '',
  comments: '',
  condition: '',
  coordinates: '',
  delta_comments: [],
  id: '',
  images: [],
  name: '',
  observation_datetime: '',
  overlay_name: '',
  platform_type: '',
  priority: '',
  quantity: 0,
  reliability_credibility: '',
  staff_comment: '',
  status: 'unplanned',
  created_at: '',
  updated_at: '',
  missions: [defaultMission],
};

export default defaultTarget;
