import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import api, { apiURLs } from 'services/api';
import PageHeader from 'components/pageHeader';
import { colors } from 'theme';
import { IOverviewResponse } from 'types/overview';
import { missionStatuses } from 'constants/mission';
import { TARGET_STATUSES } from 'constants/target';
import { useNavigate } from 'react-router-dom';
import { urls } from 'router';
import OverviewCard from '../components/overviewCard';
import { crewStatuses } from '../../crews/services/constants';

function Overview() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data,
  } = useQuery({
    queryKey: ['counts'],
    queryFn: (): Promise<IOverviewResponse> => api.get(apiURLs.count)
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const getCount = (entity: 'mission' | 'crew' | 'target', status: string) => {
    if (!data) return 0;
    return data[entity].find((m) => m.status === status)?.count || 0;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader title={t('overview')} hideSearch />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" color={colors.grayscale.g2}>{t('missions')}</Typography>
      </Grid>
      {missionStatuses.map((status) => (
        <Grid item xs={12} md={6} lg={3} xl key={status}>
          <OverviewCard
            counter={getCount('mission', status)}
            title={t(`status_mission_${status}`)}
            onClick={() => navigate(urls.missions.landing)}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="h3" color={colors.grayscale.g2} mt={1}>{t('targets')}</Typography>
      </Grid>
      {TARGET_STATUSES.map((status) => (
        <Grid item xs={12} md={6} lg={3} key={status}>
          <OverviewCard
            counter={getCount('target', status)}
            title={t(`status_targets_${status}`)}
            onClick={() => navigate(urls.targets.landing)}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="h3" color={colors.grayscale.g2} mt={1}>{t('crews')}</Typography>
      </Grid>
      {crewStatuses.map((status) => (
        <Grid item xs={12} md={6} lg={4} key={status}>
          <OverviewCard
            counter={getCount('crew', status)}
            title={t(`status_crew_${status}`)}
            onClick={() => navigate(urls.crews.landing)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default Overview;
