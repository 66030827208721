import React from 'react';
import Header from 'components/header/header';
import { Container, Stack } from '@mui/material';

import { colors } from 'theme';
import { adminNavigationItems, pilotNavigationItems, topNavigationItems } from './navigation';
import Footer from '../footer/footer';

const getNavigationItems = (admin?: boolean, pilot?: boolean, noCrew?: boolean) => {
  if (admin) {
    return adminNavigationItems;
  } if (pilot) {
    if (noCrew) {
      return [];
    }
    return pilotNavigationItems;
  }
  return topNavigationItems;
};

interface HeaderLayoutProps {
    admin?: boolean;
    pilot?: boolean;
    noCrew?: boolean;
    statusSwitcher?: React.ReactNode;
    children: React.ReactNode;
}

function HeaderLayout({
  admin, pilot, statusSwitcher, children, noCrew,
}: HeaderLayoutProps) {
  const navigationItems = getNavigationItems(admin, pilot, noCrew);
  return (
    <div style={{ backgroundColor: colors.grayscale.b3, minHeight: '100vh' }}>
      <Header navigationItems={navigationItems} statusSwitcher={statusSwitcher} />
      <Container maxWidth="xl">
        <Stack pb="88px">
          {children}
        </Stack>
      </Container>
      <Footer />
    </div>
  );
}

HeaderLayout.defaultProps = {
  admin: false,
  pilot: false,
  noCrew: false,
  statusSwitcher: null,
};

export default HeaderLayout;
