import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { ICrew } from 'types/crew';
import api, { apiURLs } from 'services/api';
import CreateCrew from './createCrew';

function EditCrew() {
  const { id = '' } = useParams();

  const {
    data: crewDetails,
  } = useQuery({
    queryKey: ['crew-details'],
    queryFn: (): Promise<ICrew> => api.get(apiURLs.crews.details(id))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  return (
    <CreateCrew currentCrew={crewDetails} crewId={id} />
  );
}

export default EditCrew;
