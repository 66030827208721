import React from 'react';

interface IProps {
  color?: string
}

function BombIcon({ color }: IProps) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.88387 14.6135C3.59668 14.6135 2.50257 14.1579 1.60154 13.2465C0.700515 12.3352 0.25 11.2359 0.25 9.94876C0.25 8.66157 0.695366 7.57261 1.5861 6.68188C2.47683 5.79115 3.56579 5.34578 4.85297 5.34578H5.05378L5.47082 4.61981C5.59439 4.39326 5.77975 4.24653 6.02689 4.17959C6.27403 4.11266 6.51087 4.14612 6.73741 4.27999L7.2008 4.54258L7.27803 4.41901C7.51487 3.97622 7.88558 3.68789 8.39016 3.55402C8.89474 3.42015 9.36842 3.48194 9.81121 3.73937L10.3518 4.0483L9.73398 5.11409L9.19336 4.80516C9.0492 4.72278 8.89216 4.70476 8.72225 4.7511C8.55235 4.79744 8.4262 4.89269 8.34382 5.03686L8.26659 5.16043L8.88444 5.51569C9.10069 5.63926 9.24228 5.82461 9.30921 6.07175C9.37614 6.31889 9.34783 6.55059 9.22426 6.76683L8.80721 7.50825C9.04405 7.87896 9.22168 8.27284 9.3401 8.68989C9.45852 9.10694 9.51773 9.53686 9.51773 9.97965C9.51773 11.2668 9.06722 12.3609 8.16619 13.262C7.26516 14.163 6.17105 14.6135 4.88387 14.6135ZM11.8965 5.96363V4.72793H13.75V5.96363H11.8965ZM8.49828 2.56546V0.711914H9.73398V2.56546H8.49828ZM11.5103 3.81661L10.6453 2.95162L11.9582 1.63869L12.8232 2.50368L11.5103 3.81661Z" fill={color} />
    </svg>
  );
}

BombIcon.defaultProps = {
  color: 'currentColor',
};

export default BombIcon;
