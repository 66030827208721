import { IMission } from 'types/mission';
import defaultCrew from './crew';

const defaultMission: IMission = {
  id: '',
  name: '',
  type: 'RECON',
  due_date: '',
  status: 'planned',
  reports: [],
  target: [],
  crew: defaultCrew,
  ammo: [],
  comments: '',
  finished: false,
  delta_comments: '',
  created_at: '',
};

export default defaultMission;
